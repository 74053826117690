






import Vue from "vue"
import { ROUTES } from "@/constants"
import { Component, Prop } from "vue-property-decorator"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Maybe, Scalars } from "@/generated/graphql"

@Component
export default class UnclaimButton extends Vue {
  @Prop({ default: () => undefined })
  catId!: Maybe<Scalars["String"]>

  loading = false

  unclaim(): void {
    this.loading = true

    this.$store
      .dispatch("livestream/actions", {
        catId: this.catId,
        typeOfAction: "unclaim",
      })
      .then(response => {
        //@ts-ignore
        emitAlert(EVENTS.GLOBAL_SUCCESS, response.message)
        this.$router.push({ name: ROUTES.DASHBOARD.name })
      })
      .catch(() => emitAlert(EVENTS.GLOBAL_ERROR, "Error unclaiming session"))
      .finally(() => (this.loading = false))

    this.$gtag.event("unclaim", {
      event_category: "test_session",
      event_label: "Test Session Unclaimed",
      value: this.catId,
    })
  }
}
