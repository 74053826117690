


















import Vue from "vue"
import { Component } from "vue-property-decorator"
import RecentSessionsTable from "@/components/dashboard/recent_sessions_table.vue"
import ActiveSessionsTable from "@/components/dashboard/active_sessions_table.vue"
import ActiveSessionsFilterSheet from "@/components/dashboard/active_sessions_filter.vue"
import ActiveFilter from "@/components/dashboard/active_sessions_active_filters.vue"
import NiPage from "@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue"

@Component({
  components: {
    NiPage,
    RecentSessionsTable,
    ActiveSessionsTable,
    ActiveSessionsFilterSheet,
    ActiveFilter,
  },
})
export default class Dashboard extends Vue {
  title = "Dashboard"
  filter = {}

  created() {
    //@ts-ignore
    this.$gtag.pageview("/dashboard")
  }
}
