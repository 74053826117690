var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ni-page',{attrs:{"header":_vm.title,"has-filters":""}},[_c('template',{slot:"toolbar"},[_c('v-row',{attrs:{"justify-space-between":"","align-center":""}},[_c('v-spacer'),_c('cat-search-form-component')],1)],1),_c('template',{slot:"filters"},[_c('cat-search-active-filters')],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sessions,"loading":_vm.loading,"page":_vm.page,"server-items-length":_vm.totalCount,"items-per-page":_vm.itemsPerPage,"footer-props":{
        itemsPerPageOptions: [10, 20, 40],
      },"disable-sort":""},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name,class:("cat-testsession-" + (item.appId))},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_c('v-row',[(item.archived)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"slot":"activator","color":"secondary","text-color":"white","label":"","small":""},slot:"activator"},on),[_vm._v(" Deleted ")])]}}],null,true)},[_c('span',{staticClass:"archive-reason"},[_vm._v(_vm._s(_vm.stringHumanize(item.archivedReason)))])])],1):_vm._e(),_c('router-link',{staticClass:"router-link",attrs:{"to":{
                    name: _vm.livestreamRouteName,
                    params: { catId: item.catId },
                  }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1),_c('td',[(item.active)?_c('v-chip',{class:("test-cat-active-" + (item.appId)),attrs:{"color":"success","text-color":"white","label":"","small":""}},[_vm._v(" Active ")]):_vm._e()],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(item.user ? item.user.name : null)+" ")]),_c('td',{staticClass:"text-xs-left"},[_c('local-time',{attrs:{"time":item.createdAt}})],1),_c('td',{staticClass:"text-xs-left"},[_c('cat-menu',{attrs:{"test-session":item,"test-sessions":_vm.sessions}})],1)])}),0)]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }