












































































import Vue from "vue"
import { Component } from "vue-property-decorator"
import localTime from "@/components/common/local_time.vue"
import NiShow from "@nielsen-media/nlsn-vue-ui/lib/components/NiShow.vue"
import { ROUTES } from "@/constants"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { TestSession, User } from "@/generated/graphql"

@Component({
  components: {
    NiShow,
    localTime,
  },
})
export default class TestInfo extends Vue {
  edit = false
  historyRouteName = ROUTES.HISTORY.name

  get canUnclaim(): boolean {
    return this.$store.getters["livestream/canUnclaim"]
  }

  get testSession(): TestSession {
    return this.$store.getters["livestream/testSession"]
  }

  get streamOwner(): User {
    return this.$store.getters["livestream/streamOwner"]
  }

  get loading(): boolean {
    return this.$store.getters["livestream/loading"]
  }

  get currentUser(): User {
    return this.$store.getters["currentUser/currentUser"]
  }

  get integrationName(): string {
    return !this.testSession.integrationName ? "Click To Edit" : this.testSession.integrationName
  }

  trackClick(ts: TestSession): void {
    this.$gtag.event("go_to_dog", {
      event_category: "test_session",
      event_label: "Click to Dog App",
      value: ts.catId,
    })
  }

  allowedToUpdateName(): boolean {
    return this.streamOwner && this.streamOwner.id === this.currentUser.id
  }

  historyClick(): void {
    if (this.testSession.catId) {
      this.$router.push({ name: this.historyRouteName, params: { catId: this.testSession.catId } })
    }

    this.$gtag.event("open_history_page", {
      event_category: "test_session",
      event_label: "open_history_page",
      value: this.$route.params.catId,
    })
  }

  async updateIntegrationName(ts: TestSession): Promise<void> {
    this.edit = false
    if (!this.allowedToUpdateName()) {
      ts.integrationName = ""
      return emitAlert(EVENTS.GLOBAL_WARNING, "You cannot update TestSession's Name that is not yours.")
    }

    this.$store
      .dispatch("livestream/updateTestSession", { id: parseInt(ts.id), name: ts.integrationName, catId: ts.catId })
      .then(res => emitAlert(EVENTS.GLOBAL_SUCCESS, res.message))
      .catch(() => emitAlert(EVENTS.GLOBAL_WARNING, "There was an error updating the session"))

    this.$gtag.event("rename", {
      event_category: "test_session",
      event_label: "Test Session renamed",
      value: ts.catId,
    })
  }
}
