import { CommentsState } from "./comments"
import { Comment, User } from "@/generated/graphql"

interface PingComment extends Comment {
  messages: { message: string | null | undefined; user: User }[]
}

export default {
  catId(state: CommentsState) {
    return state.catId
  },
  commentCount(state: CommentsState) {
    return state.commentCount
  },
  tsComments(state: CommentsState) {
    return state.tsComments
  },
  pingComments(state: CommentsState) {
    // TODO: will be used for grouped ping comments in details view (pending discussion on view)
    const reduced = state.pingComments.reduce((arr: PingComment[], comment: Comment) => {
      const index = arr.findIndex(c => c.commentableId == comment.commentableId)
      if (index > -1) {
        arr[index].messages.push({
          message: comment.message,
          user: comment.user,
        })
      } else {
        arr.push({
          ...comment,
          messages: [{ message: comment.message, user: comment.user }],
        })
      }
      return arr
    }, [])
    return reduced
  },
  loading(state: CommentsState) {
    return state.loading
  },
  loadMoreComments(state: CommentsState) {
    return state.loadMoreComments
  },
  message(state: CommentsState) {
    return state.message
  },
}
