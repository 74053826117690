




import moment from "moment"
import Vue from "vue"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"
import { Maybe, Scalars } from "@/generated/graphql"

@Component
export default class LocalTime extends Vue {
  @Prop({ default: () => undefined })
  time!: Maybe<Scalars["String"]>

  @Prop({ default: () => undefined })
  editedTime!: Maybe<Scalars["String"]>

  convertedTime(): string {
    if (!this.time) return "N/A"
    if (!Date.parse(this.time) || (this.editedTime && !Date.parse(this.editedTime))) return "Unparseable Date"
    if (this.editedTime && this.editedTime !== this.time) return moment(this.editedTime).format("lll")
    return moment(this.time).format("lll")
  }
}
