import { User } from "@/generated/graphql"
/* eslint-disable */

const humanize = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .join(" ")
    .toLowerCase();
};

const sanatize = (filters: any, users: User[]) => {
  const chips = [];
  for (let k in filters) {
    let fKey = k;
    let fValue = filters[k];
    if (k == "userId") {
      fKey = "User";
      const user = users.filter(u => u.id == filters[k])[0];
      fValue = user ? user.name : filters[k];
    }

    if (fValue && fValue != '') {
      chips.push(`${humanize(fKey)}: ${fValue}`);
    }
  }
  return chips;
};

export { sanatize };
