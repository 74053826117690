














import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import catStreamStreamFactHeadline from "./fact_headline.vue"
import NiShow from "@nielsen-media/nlsn-vue-ui/lib/components/NiShow.vue"

@Component({
  components: {
    NiShow,
    catStreamStreamFactHeadline,
  },
})
export default class FactSummary extends Vue {
  @Prop({ default: () => "" })
  headline!: string

  @Prop({ default: () => ({}) })
  details!: { [key: string]: any[] }
}
