import { PreflightState } from "./preflight"

export default {
  all(state: PreflightState) {
    return state.preflights
  },
  get(state: PreflightState) {
    return state.pfTypes
  },
  loading(state: PreflightState) {
    return state.loading
  },
  itemsPerPage(state: PreflightState) {
    return state.itemsPerPage
  },
  page(state: PreflightState) {
    return state.page
  },
  pageInfo(state: PreflightState) {
    return state.pageInfo
  },
  totalCount(state: PreflightState) {
    return state.totalCount
  },
  identifierAutocompleteItems(state: PreflightState) {
    const items = [] as any
    const devTypeIdsPresent = state.identifier.devTypeIds.length > 0
    const devIdsPresent = state.identifier.devIds.length > 0
    if (devIdsPresent) {
      items.push({ header: "Your recent Cookies" })
      state.identifier.devIds.forEach(d => items.push(d))
    }
    if (devIdsPresent && devTypeIdsPresent) {
      items.push({ divider: true })
    }
    if (devTypeIdsPresent) {
      items.push({ header: "Apps" })
      state.identifier.devTypeIds.forEach(d => items.push(d))
    }
    return items
  },
}
