












import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FactId3Table extends Vue {
  @Prop({ default: () => "" })
  headline!: string

  @Prop({ default: () => [] })
  details!: { [key: string]: any }

  search = ""
  tableHeaders = [
    { text: "Client", value: "name" },
    { text: "SID", value: "sid" },
    { text: "TIC", value: "tic" },
    { text: "Type", value: "type" },
    { text: "Timestamp", value: "timestamp" },
  ]
}
