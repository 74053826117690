export const EVENTS = {
  AUTHENTICATED: "AUTHENTICATED",
  GLOBAL_WARNING: "GLOBAL_WARNING",
  GLOBAL_ERROR: "GLOBAL_ERROR",
  GLOBAL_SUCCESS: "GLOBAL_SUCCESS",
}
export const ROUTES = {
  DASHBOARD: {
    name: "Dashboard",
    path: "/",
  },
  TEST_SESSIONS: {
    name: "Test Sessions",
    path: "/tests",
  },
  STREAM: {
    name: "Livestream",
    path: "/test/:catId/stream",
  },
  // NOTE: old url paths to cat sessions saved across jira/google docs
  OLD_STREAM_ROUTE: {
    name: "Livestream",
    path: "/app/test/:catId/stream",
  },
  HISTORY: {
    name: "History",
    path: "/test/:catId/history",
  },
  SETUP: {
    name: "DNS Setup",
    path: "/setup",
  },
  TROUBLESHOOTING: {
    name: "Troubleshooting",
    path: "/troubleshooting",
  },
  PREFLIGHT: {
    name: "Preflight",
    path: "/preflights",
  },
}
export const SEARCH_MIN_CHARACTER = 3
