








import Vue from "vue"
import axios from "axios"
import NiShow from "@nielsen-media/nlsn-vue-ui/lib/components/NiShow.vue"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"

@Component({
  components: {
    NiShow,
  },
})
export default class DnsCheckItem extends Vue {
  @Prop({ default: () => "" })
  url!: string

  status = "running"
  color = "primary"

  created(): void {
    this.verify()
  }

  verify(): void {
    axios
      .get(`//${this.url}/api/v1/health/env`)
      .then(res => {
        if (process.env.VUE_APP_ENV == res.data) {
          this.status = "ok"
          this.color = "green"
        } else {
          this.status = res.data.toLowerCase().startsWith("gif89a") ? "no dns setup" : `${res.data} dns`
          this.color = "red"
        }
      })
      .catch(() => {
        this.status = "no dns setup"
        this.color = "red"
      })
  }
}
