



























































import Vue from "vue"
import { Component } from "vue-property-decorator"
import catPingLabels from "./ping_labels.vue"
import catFactSummary from "./fact_summary.vue"
import catFactCollapsibleSummary from "./fact_collapsible_summary.vue"
import catFactTable from "./fact_table.vue"
import catFactId3Table from "./fact_id3_table.vue"
import catFactRaw from "./fact_raw.vue"
import catFactAlerts from "./fact_alerts.vue"
import catFactCopy from "./fact_copy.vue"
import catNewComment from "@/components/comments/new.vue"
import catCommentView from "@/components/comments/view.vue"
import { ROUTES } from "@/constants"
import { Ping } from "@/generated/graphql"

@Component({
  components: {
    catFactRaw,
    catFactCopy,
    catFactTable,
    catFactAlerts,
    catPingLabels,
    catFactSummary,
    catFactId3Table,
    catFactCollapsibleSummary,
    catNewComment,
    catCommentView,
  },
})
export default class PingDetail extends Vue {
  activeTab = 0

  get selectedPing(): Ping {
    return this.$store.getters["livestream/selectedPing"]
  }

  mounted(): void {
    if (this.$route.query.comments) this.activeTab = 1
  }

  closePingDetail(): void {
    this.$store.dispatch("livestream/closePingDetail")
    this.setRouter("clear")
  }

  setRouter(tab: string): void {
    let query
    switch (tab) {
      case "comments":
        query = { pingId: this.$route.query.pingId, comments: true.toString() }
        break
      case "pingInfo":
        query = { pingId: this.$route.query.pingId }
        break
      case "clear":
        query = {}
        break
      default:
        query = {}
    }

    this.$router.replace({
      name: ROUTES.STREAM.name,
      query,
    })
  }
}
