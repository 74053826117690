import productMap from "@/helpers/product_map.helper"
import store from "../../index"
import { getCondensed } from "@/helpers/condensed_filter.helper"
import { LivestreamState } from "./livestream"
import { App } from "@/generated/graphql"

export default {
  focused(state: LivestreamState) {
    return state.focused
  },
  loading(state: LivestreamState) {
    return state.loading
  },
  pingLoading(state: LivestreamState) {
    return state.pingLoading
  },
  pings(state: LivestreamState) {
    let pings = state.pings
    if (state.filters.showWarningsAndErrorsOnly) {
      pings = pings.filter(p => Object.keys(p.warning_messages).length || Object.keys(p.error_messages).length)
    }

    if (state.filters.showUserInteractionsOnly) {
      pings = pings.filter(p => p.description || p.commented_on)
    }

    if (Object.keys(state.filters.showOnlyProducts).length) {
      const products = state.filters.showOnlyProducts
      const showOnlyProducts = Object.keys(products).filter(product => products[product])
      if (showOnlyProducts.length) {
        pings = pings.filter(p => productMap.types(showOnlyProducts).includes(p.filter_type!))
      }
    }

    if (Object.keys(state.filters.showOnlyAppIds).length) {
      const appIds = state.filters.showOnlyAppIds
      const showOnlyAppIds = Object.keys(appIds).filter(appId => appIds[appId])

      if (showOnlyAppIds.length) {
        pings = pings.filter(p => showOnlyAppIds.includes(p.app_id!) || !p.app_id)
      }
    }

    if (state.filters.custom && state.filters.custom.length) {
      pings = pings.filter(
        p =>
          p.labels
            .join(" ")
            .toLowerCase()
            .indexOf(state.filters.custom) > -1
      )
    }

    if (state.filters.condensed) {
      pings = getCondensed(pings)
    }

    return pings
  },
  selectedPing(state: LivestreamState) {
    return state.selectedPing
  },
  products() {
    return productMap.products()
  },
  appIds(state: LivestreamState) {
    if (state.testSession.apps) {
      return state.testSession.apps.map((app: App) => app.appId)
    }
  },
  testSession(state: LivestreamState) {
    return state.testSession
  },
  disableBtnDotUi(state: LivestreamState) {
    return state.testSession.initiator == "dot_ui"
  },
  filters(state: LivestreamState) {
    return state.filters
  },
  descriptions(state: LivestreamState) {
    return state.descriptions
  },
  presence(state: LivestreamState) {
    return state.presence
  },
  streamOwner(state: LivestreamState) {
    return state.user
  },
  canUnclaim(state: LivestreamState): boolean | undefined {
    const currentUser = store.getters["currentUser/currentUser"]
    return state.user && state.user.id === currentUser.id && state.testSession.initiator != "dot_ui"
  },
  canUpdateDescriptions(state: LivestreamState): boolean | undefined | null {
    const currentUser = store.getters["currentUser/currentUser"]
    return state.user && state.user.id === currentUser.id && state.testSession.active
  },
  activeTestSession(state: LivestreamState) {
    return state.testSession && state.testSession.active
  },
  title(state: LivestreamState) {
    const showAppId =
      state.testSession && state.testSession.appId && state.testSession.integrationName != state.testSession.appId
        ? ` (${state.testSession.appId})`
        : ""
    return state.testSession && state.testSession.integrationName
      ? `${state.testSession.integrationName}${showAppId}`
      : "Livestream"
  },

  truncatedData(state: LivestreamState) {
    return { truncated: state.testSession?.truncated, truncatedDate: state.testSession?.truncatedAt }
  },

  wrapperClasses(state: LivestreamState) {
    let classes = "cat-stream-wrapper"
    if (state.selectedPing.details && state.selectedPing.details.id) classes = `${classes} has-details`
    if (state.filters.showClock) classes = `${classes} has-details has-clock`
    return classes
  },
  focusedPingId(state: LivestreamState) {
    return state.pings[state.focused] && state.pings[state.focused].id ? state.pings[state.focused].id : 0
  },
  filterTerm(state: LivestreamState) {
    return state.filters.custom
  },
}
