import Centrifuge from "centrifuge"

const setCentrifugoJwt = (jwt: string) => {
  localStorage.setItem("centrifugoJwt", jwt)
}
const getCentrifugoJwt = () => {
  return localStorage.getItem("centrifugoJwt")
}

// TODO: figure out why process.env not respected here for url
const centrifuge = new Centrifuge(process.env.VUE_APP_WEBSOCKET_ENDPOINT!, {
  //@ts-ignore
  insecure: true,
})

centrifuge.setToken(getCentrifugoJwt()!)
centrifuge.connect()

export default centrifuge

export { setCentrifugoJwt, getCentrifugoJwt }
