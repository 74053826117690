import insertIntoTimeline from "@/helpers/timeline.helper"
import { LivestreamState, LivestreamFilters, LivestreamPing } from "./livestream"
import { App, Comment, User, TestSession } from "@/generated/graphql"

function newClientIdHandler(state: LivestreamState, data: any) {
  if (!state.testSession.appClientIds.includes(data.client_id)) {
    state.testSession.appClientIds = [...state.testSession.appClientIds, data.client_id]
  }
}

export default {
  setCatId(state: LivestreamState, catId: string) {
    state.catId = catId
  },
  addPing(state: LivestreamState, ping: LivestreamPing) {
    if (state.pings.find(p => p.id === ping.id)) return
    state.pings = insertIntoTimeline(state.pings, ping)
  },
  addNewApp(state: LivestreamState, data: any) {
    const app = { appId: data.app_id, dogLink: data.dog_link }
    state.testSession.apps = [...state.testSession.apps, app]
    state.appIds = [...state.appIds, data.app_id]

    newClientIdHandler(state, data)
  },
  addNewClientId(state: LivestreamState, data: any) {
    newClientIdHandler(state, data)
  },
  addAppId(state: LivestreamState, appId: string) {
    state.appIds = [...state.appIds, appId]
  },
  setTestSession(state: LivestreamState, testSession: TestSession) {
    state.testSession = testSession
  },
  setConnection(state: LivestreamState, connection: any) {
    state.connection = connection
  },
  setAppIds(state: LivestreamState, apps: App[]) {
    const appIds = apps.map(app => app.appId)
    state.appIds = appIds
  },
  setPings(state: LivestreamState, value: LivestreamPing[]) {
    state.pings = value
  },
  setPing(
    state: LivestreamState,
    payload: {
      focusedId: string
      ping: { details: { [key: string]: string }; filterType: string; comments: Comment[] }
    }
  ) {
    const index = state.pings.findIndex(e => e.id == parseInt(payload.focusedId))
    state.selectedPing = payload.ping
    state.focused = index
  },
  setDescriptions(state: LivestreamState, descriptions: string[]) {
    state.descriptions = descriptions
  },
  addUserToRoom(state: LivestreamState, user: any) {
    if (state.presence.some((e: any) => e.user === user.user)) return
    state.presence.push(user)
  },
  removeUserFromRoom(state: LivestreamState, client: any) {
    state.presence = state.presence.filter((u: any) => u.client !== client)
  },
  setPresence(state: LivestreamState, value: any) {
    state.presence = value
  },
  deactivateTestSession(state: LivestreamState, data: { deactivation_reason: undefined; deactivated_at: any }) {
    state.testSession.active = false
    if (data.deactivation_reason != undefined) {
      state.testSession.deactivatedAt = data.deactivated_at
      state.testSession.deactivationReason = data.deactivation_reason
    }
  },
  updatePing(state: LivestreamState, description: { id: string; value: string | null }) {
    state.pings = state.pings.map(p => {
      if (p.id == parseInt(description.id)) {
        p.description = description.value
      }

      if (p.condensedInfo && p.condensedInfo.end.id == description.id) {
        p.condensedInfo.end.description = description.value
      }

      return p
    })
  },
  setSessionUser(state: LivestreamState, user: User) {
    state.user = user
  },
  setFocused(state: LivestreamState, position: number) {
    state.focused = position
  },
  setFilters(state: LivestreamState, filter: LivestreamFilters) {
    state.filters = filter
  },
  setFilterKey(state: LivestreamState, payload: { key: string | number; value: any }) {
    state.filters[payload.key] = payload.value
  },
  setLoading(state: LivestreamState, loading: boolean) {
    state.loading = loading
  },
  setPingLoading(state: LivestreamState, loading: boolean) {
    state.pingLoading = loading
  },
  resetFilters(state: LivestreamState) {
    state.filters = {
      showWarningsAndErrorsOnly: false,
      showUserInteractionsOnly: false,
      highlightUserInteractions: true,
      showOnlyProducts: {},
      showOnlyAppIds: {},
      showTimestamps: true,
      showClock: false,
      custom: "",
      condensed: false,
    }
  },
  clearTestSession(state: LivestreamState) {
    state.testSession = {}
    state.catId = null
    state.filters = {
      showWarningsAndErrorsOnly: false,
      showUserInteractionsOnly: false,
      highlightUserInteractions: true,
      showOnlyProducts: {},
      showOnlyAppIds: {},
      showTimestamps: true,
      showClock: false,
      custom: "",
      condensed: false,
    }
  },
  updateCustomFilter(state: LivestreamState, filterTerm: string) {
    state.filters = { ...state.filters, custom: filterTerm }
  },
  addComment(state: LivestreamState, comment: Comment) {
    state.selectedPing.comments.push(comment)
    state.pings = state.pings.map(p => {
      if (p.id == comment.commentableId) p.commented_on = true
      return p
    })
  },
  updateComment(state: LivestreamState, comment: Comment) {
    const index = state.selectedPing.comments.findIndex(c => c.id === comment.id)
    state.selectedPing.comments[index] = comment
  },
  deleteComment(state: LivestreamState, id: number | string) {
    const index = state.selectedPing.comments.findIndex(c => c.id === id)
    state.selectedPing.comments.splice(index, 1)
    state.pings = state.pings.map(p => {
      if (p.id == state.selectedPing.details.id && !state.selectedPing.comments.length) {
        p.commented_on = false
      }
      return p
    })
  },
}
