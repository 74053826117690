











import Vue from "vue"
import { Component } from "vue-property-decorator"
import { ROUTES } from "@/constants"

@Component
export default class ViewChooser extends Vue {
  toggle = 0
  historyRouteName = ROUTES.HISTORY.name
  livestreamRouteName = ROUTES.STREAM.name

  created(): void {
    if (this.$route.name === this.livestreamRouteName) this.toggle = 0
    if (this.$route.name === this.historyRouteName) this.toggle = 1
  }

  goAndTrack(feature: string): void {
    const routeName = feature == "open_history_page" ? this.historyRouteName : this.livestreamRouteName
    this.$router.push({ name: routeName, params: { catId: this.$route.params.catId } })
    this.$gtag.event(feature, {
      event_category: "test_session",
      event_label: feature,
      value: this.$route.params.catId,
    })
  }
}
