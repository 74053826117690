import actions from "./actions.preflight"
import getters from "./getters.preflight"
import mutations from "./mutations.preflight"
import { Maybe, PageInfo, Preflight, Scalars } from "@/generated/graphql"

export interface PreflightState {
  loading: boolean
  page: number
  itemsPerPage: number
  totalCount: number
  pageInfo: PageInfo
  connection: any
  pfTypes: Maybe<Scalars["Json"]>
  preflight: Preflight | {}
  preflights: Preflight[]
  identifier: { devIds: string[]; devTypeIds: string[] }
}

export const state: PreflightState = {
  loading: false,
  page: 1,
  itemsPerPage: 10,
  totalCount: 0,
  pageInfo: {
    hasNextPage: true,
    hasPreviousPage: false,
    endCursor: "0",
    startCursor: "0",
  },
  connection: null,
  pfTypes: [],
  preflight: {},
  preflights: [],
  identifier: {
    devIds: [],
    devTypeIds: [],
  },
}

export const getPreflightIndexById = (preflights: Preflight[], id: string) => {
  return preflights.findIndex(p => p.id == id)
}

const Preflights = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
}

export default Preflights
