


































































import Vue from "vue"
import { ROUTES } from "@/constants"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { getToken } from "@nielsen-media/auth-api/src/auth"
import { TestSession, Maybe, Scalars, User } from "@/generated/graphql"

@Component
export default class Menu extends Vue {
  @Prop({ default: () => ({}) })
  testSession!: TestSession

  @Prop({ default: () => [] })
  testSessions!: TestSession[]

  dialog = false
  reason = null
  historyRouteName = ROUTES.HISTORY.name

  created(): void {
    this.$store.dispatch("testSessions/loadReasons")
  }

  get reasons(): Maybe<Scalars["Json"]> {
    return this.$store.getters["testSessions/reasons"]
  }

  get currentUser(): User {
    return this.$store.getters["currentUser/currentUser"]
  }

  archiveCheck(testSession: TestSession): boolean | undefined {
    if (testSession.user != undefined) {
      //@ts-ignore
      return this.$auth.hasPermissions("cat.admin") || testSession.user.id == this.currentUser.id
    }
  }

  toggleArchived(testSession: TestSession): void {
    const archived = testSession.archived == true ? false : true
    const archivedReason = this.reason
    this.updateArchive(testSession, archived, archivedReason)
  }

  updateArchive(testSession: TestSession, archived: boolean, archivedReason: number | null): void {
    const hash = {
      id: parseInt(testSession.id),
      archived: archived,
      archivedReason: archivedReason,
    }

    this.$store.dispatch("testSessions/updateTestSession", hash).then(response => {
      emitAlert(EVENTS.GLOBAL_SUCCESS, response.message)
      this.dialog = false
      this.reason = null
      const session = response.testSession
      testSession.archived = session.archived
      testSession.archivedReason = session.archivedReason
      //@ts-ignore
      if (!this.$auth.hasPermissions("cat.admin") && session.user.id == this.currentUser.id) {
        const index = this.testSessions.findIndex(function(element) {
          return element.id == session.id
        })
        this.testSessions.splice(index, 1)
      }
    })
    this.$gtag.event("archive", {
      event_category: "test_session",
      event_label: "Test Session Archived",
      value: testSession.catId,
    })
  }

  async exportTs(ts: TestSession): Promise<void> {
    const token = await getToken()

    window.open(
      `${process.env.VUE_APP_EXPORT_URL_BASE}/app/test-session/export/${ts.id}?format=xlsx&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }&token=${token}`
    )

    this.$gtag.event("export", {
      event_category: "test_session",
      event_label: "Test Session Exported",
      value: ts.id,
    })
  }
}
