/* eslint-disable */
import { LivestreamPing } from "@/store/modules/livestream/livestream"

function isPlayhead(p: LivestreamPing) {
  return (
    p.filter_type == "pings_dcr_debug_playhead" ||
    p.filter_type == "pings_dcr_debug_staticplayhead"
  );
}

function getCondensed(pings: LivestreamPing[]) {
  let startingPlayhead: number|null;
  let startingPlayheadIndex: number|null;
  let startingFilterType: string|null;
  let currentPlayhead: number|null;

  pings = pings.filter((p, i) => {
    if (isPlayhead(p)) {
      let playheadPostion = parseInt(p.labels[3]);
      let filterType = p.filter_type;
      if (!startingPlayhead && startingPlayhead !== 0) {
        currentPlayhead = playheadPostion;
        startingPlayhead = playheadPostion;
        startingFilterType = filterType;
        startingPlayheadIndex = i;
        p.condensedInfo = {
          start: {
            id: p.id,
            position: playheadPostion,
            description: p.description
          },
          end: {
            id: null,
            position: null,
            description: null,
            commented_on: null,
          }
        };
        return p;
      } else if (
        //@ts-ignore
        playheadPostion == currentPlayhead + 1 &&
        startingFilterType == filterType
      ) {
        //@ts-ignore
        pings[startingPlayheadIndex].condensedInfo = {
          //@ts-ignore
          ...pings[startingPlayheadIndex].condensedInfo,
          end: {
            id: p.id,
            position: playheadPostion,
            description: p.description,
            commented_on: p.commented_on,
          }
        };
        //@ts-ignore
        let newLabels = [...pings[startingPlayheadIndex].labels];
        newLabels[3] = `${startingPlayhead} - ${p.labels[3]}`;
        //@ts-ignore
        pings[startingPlayheadIndex].labels = newLabels;
        currentPlayhead = playheadPostion;
      } else {
        if (isPlayhead(p)) {
          currentPlayhead = playheadPostion;
          startingPlayhead = playheadPostion;
          startingFilterType = filterType;
          startingPlayheadIndex = i;
          p.condensedInfo = {
            start: {
              id: p.id,
              position: playheadPostion,
              description: p.description,
              commented_on: p.commented_on,
            },
            end: {
              id: null,
              position: null,
              description: null,
              commented_on: null,
            }
          };
          return p;
        } else {
          startingPlayhead = null;
          currentPlayhead = null;
          return p;
        }
      }
    } else {
      startingPlayhead = null;
      currentPlayhead = null;
      startingPlayheadIndex = null;
      return p;
    }
  });
  return pings;
}

export { getCondensed, isPlayhead };
