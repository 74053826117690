import Vue from "vue"
import Vuex, { Store } from "vuex"
import activeSessions, { ActiveSessionsState } from "./modules/active-sessions/active-sessions"
import livestream, { LivestreamState } from "./modules/livestream/livestream"
import currentUser, { CurrentUserState } from "./modules/current-user/current-user"
import testSessions, { TestSessionsState } from "./modules/test-sessions/test-sessions"
import comments, { CommentsState } from "./modules/comments/comments"
import preflights, { PreflightState } from "./modules/preflight/preflight"
import tsHistory, { TsHistoryState } from "./modules/ts-history/ts-history"

Vue.use(Vuex)

export interface RootState {
  activeSessions: ActiveSessionsState
  livestream: LivestreamState
  currentUser: CurrentUserState
  testSessions: TestSessionsState
  comments: CommentsState
  preflights: PreflightState
  tsHistory: TsHistoryState
}

const store = new Store<RootState>({
  modules: {
    activeSessions,
    livestream,
    currentUser,
    testSessions,
    comments,
    preflights,
    tsHistory,
  },
})

export default store
