

























































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import filters from "@/components/stream/filters.vue"
import displaySettings from "@/components/stream/display_settings.vue"
import testInfo from "@/components/stream/test_info.vue"
import endSessionButton from "@/components/common/buttons/end_session_button.vue"
import catNewComment from "@/components/comments/new.vue"
import catCommentView from "@/components/comments/view.vue"
import catClaimButton from "@/components/common/buttons/claim_button.vue"
import catUnclaimButton from "@/components/common/buttons/unclaim_button.vue"
import { getToken } from "@nielsen-media/auth-api/src/auth"
import { Comment, TestSession, User } from "@/generated/graphql"

@Component({
  components: {
    endSessionButton,
    displaySettings,
    filters,
    testInfo,
    catNewComment,
    catCommentView,
    catClaimButton,
    catUnclaimButton,
  },
})
export default class TestSessionPanels extends Vue {
  @Prop({ default: () => "" })
  header!: string

  panels = [
    { header: "Information", component: "test-info" },
    { header: "Display Settings", component: "display-settings" },
    { header: "Filters", component: "filters" },
  ]
  panelopen = [0]

  get testSession(): TestSession {
    return this.$store.getters["livestream/testSession"]
  }

  get canUnclaim(): boolean {
    return this.$store.getters["livestream/canUnclaim"]
  }

  get streamOwner(): User {
    return this.$store.getters["livestream/streamOwner"]
  }

  get tsComments(): Comment[] {
    return this.$store.getters["comments/tsComments"]
  }

  get catId(): string {
    return this.$store.getters["comments/catId"]
  }

  get loadMoreComments(): boolean {
    return this.$store.getters["comments/loadMoreComments"]
  }

  get commentCount(): number {
    return this.$store.getters["comments/commentCount"]
  }

  loadComments(): void {
    this.$store.dispatch("comments/loadComments", {
      catId: this.testSession.catId,
      first: this.commentCount,
    })
  }

  async exportTs(ts: TestSession): Promise<void> {
    const token = await getToken()

    fetch(
      `${process.env.VUE_APP_EXPORT_URL_BASE}/app/test-session/export/${ts.id}?format=xlsx&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then(response => response.blob())
      .then(blob => {
        const dllink = window.document.createElement("a")
        dllink.href = window.URL.createObjectURL(blob)
        dllink.setAttribute("download", `${ts.catId}.xlsx`)
        dllink.click()
      })
      .catch(err => {
        console.log(err)
      })

    this.$gtag.event("export", {
      event_category: "test_session",
      event_label: "Test Session Exported",
      value: ts.catId,
    })
  }
}
