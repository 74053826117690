import actions from "./actions.current-user"
import getters from "./getters.current-user"
import mutations from "./mutations.current-user"
import { PageInfo, User, TestSession } from "@/generated/graphql"

export const resetDefaults = () => {
  return {
    currentUser: {},
    testSessions: [],
    loading: false,
    page: 1,
    itemsPerPage: 10,
    totalCount: 0,
    pageInfo: {
      hasNextPage: true,
      hasPreviousPage: false,
      endCursor: "0",
      startCursor: "0",
    },
  }
}

export interface CurrentUserState {
  currentUser: User | {}
  testSessions: TestSession[]
  loading: boolean
  page: number
  itemsPerPage: number
  totalCount: number
  pageInfo: PageInfo
}

export const state: CurrentUserState = {
  currentUser: {},
  testSessions: [],
  loading: false,
  page: 1,
  itemsPerPage: 10,
  totalCount: 0,
  pageInfo: {
    hasNextPage: true,
    hasPreviousPage: false,
    endCursor: "0",
    startCursor: "0",
  },
}

const CurrentUser = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
}

export default CurrentUser
