import Vue from "vue"
import VueGtag from "vue-gtag"
import { ROUTES } from "@/constants"
import VueRouter, { RouteConfig } from "vue-router"
import Dashboard from "../views/dashboard/Index.vue"
import TestSessions from "../views/tests/Index.vue"
import LiveStream from "../views/tests/Stream.vue"
import History from "../views/tests/History.vue"
import Setup from "../views/setup/Index.vue"
import Preflight from "../views/preflights/Index.vue"
import Troubleshooting from "../views/troubleshooting/Index.vue"

// @ts-ignore
import { withAuth } from "@nielsen-media/auth-api/src/auth"
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: ROUTES.DASHBOARD.path,
    name: ROUTES.DASHBOARD.name,
    component: Dashboard,
  },
  {
    path: ROUTES.TEST_SESSIONS.path,
    name: ROUTES.TEST_SESSIONS.name,
    component: TestSessions,
  },
  {
    path: ROUTES.STREAM.path,
    name: ROUTES.STREAM.name,
    component: LiveStream,
  },
  {
    path: ROUTES.OLD_STREAM_ROUTE.path,
    name: ROUTES.OLD_STREAM_ROUTE.name,
    component: LiveStream,
  },
  {
    path: ROUTES.HISTORY.path,
    name: ROUTES.HISTORY.name,
    component: History,
  },
  {
    path: ROUTES.SETUP.path,
    name: ROUTES.SETUP.name,
    component: Setup,
  },
  {
    path: ROUTES.TROUBLESHOOTING.path,
    name: ROUTES.TROUBLESHOOTING.name,
    component: Troubleshooting,
  },
  {
    path: ROUTES.PREFLIGHT.path,
    name: ROUTES.PREFLIGHT.name,
    component: Preflight,
  },
]

const router = withAuth(
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  }),
  process.env.VUE_APP_ENABLE_OKTA==='true', process.env.VUE_APP_ENABLE_AUTH0==='true'
)


Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA || "UA-121478784-1" },
    params: {
      send_page_view: true,
    },
    appName: "CAT",
    pageTrackerScreenviewEnabled: true,
  },
  router
)

export default router
