import actions from "./actions.ts-history"
import getters from "./getters.ts-history"
import mutations from "./mutations.ts-history"
import { Version, PageInfo } from "@/generated/graphql"

export interface TsHistoryState {
  history: Version[]
  loading: boolean
  page: number
  itemsPerPage: number
  totalCount: number
  pageInfo: PageInfo
}

export const state: TsHistoryState = {
  history: [],
  loading: false,
  page: 1,
  itemsPerPage: 10,
  totalCount: 0,
  pageInfo: {
    hasNextPage: true,
    hasPreviousPage: false,
    endCursor: "0",
    startCursor: "0",
  },
}

const TsHistory = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
}

export default TsHistory
