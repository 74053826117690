














import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class PingLabels extends Vue {
  @Prop({ default: () => [] })
  labels!: string[]

  get textLabels(): string[] {
    if (this.labels.length <= 2) return []
    return [...this.labels].splice(2)
  }
}
