




















































import Vue from "vue"
import { Component } from "vue-property-decorator"
import catPreflightStatusChip from "@/components/preflight/status_chip.vue"
import catPreflightMenu from "@/components/preflight/menu.vue"
import catPreflightDialog from "@/components/preflight/dialog.vue"
import NiPage from "@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue"
import { Preflight, PreFlightsQueryVariables, PageInfo, User } from "@/generated/graphql"

@Component({
  components: {
    NiPage,
    catPreflightMenu,
    catPreflightDialog,
    catPreflightStatusChip,
  },
})
export default class Preflights extends Vue {
  title = "Preflights"

  headers = [
    { text: "Locked", value: "locked", align: "center", width: 100 },
    { text: "Title", value: "title", align: "center" },
    { text: "App Id", value: "apid", align: "center" },
    { text: "Last modified by", value: "name", align: "center" },
    { text: "Active", value: "active", align: "center" },
    { text: "Actions", value: "", align: "right", width: 100 },
  ]

  get preflights(): Preflight[] {
    return this.$store.getters["preflights/all"]
  }

  get loading(): boolean {
    return this.$store.getters["preflights/loading"]
  }

  get itemsPerPage(): number {
    return this.$store.getters["preflights/itemsPerPage"]
  }

  get page(): number {
    return this.$store.getters["preflights/page"]
  }

  get pageInfo(): PageInfo {
    return this.$store.getters["preflights/pageInfo"]
  }

  get totalCount(): number {
    const count = this.$store.getters["preflights/totalCount"]
    return count > 10 ? count : 10
  }

  get currentUser(): User {
    return this.$store.getters["currentUser/currentUser"]
  }

  created(): void {
    this.$store.dispatch("preflights/connect")
    this.paginate(false, true)
    //@ts-ignore
    this.$gtag.pageview("/preflights")
  }

  beforeDestroy(): void {
    this.$store.dispatch("preflights/disconnect")
  }

  paginate(isPrevious: boolean, isPageLoad: boolean): void {
    const requestPayload: PreFlightsQueryVariables = { orderBy: "id desc" }

    if (isPrevious) {
      requestPayload.before = this.pageInfo?.startCursor ? parseInt(this.pageInfo.startCursor) : 0
      requestPayload.last = this.itemsPerPage
    } else if (isPageLoad) {
      this.$store.commit("preflights/setPage", 1)
      requestPayload.after = 0
      requestPayload.first = 10
    } else {
      requestPayload.after = this.pageInfo?.endCursor ? parseInt(this.pageInfo.endCursor) : 0
      requestPayload.first = this.itemsPerPage
    }
    this.$store.dispatch("preflights/paginate", requestPayload)
  }

  updatePage(pageNumber: number): void {
    if (this.loading) return
    if (this.page === pageNumber) return
    let isPrevious = false
    if (pageNumber < this.page) {
      isPrevious = true
    }

    this.setPage(pageNumber)
    this.paginate(isPrevious, false)
  }

  updateItemsPerPage(val: number): void {
    if (this.itemsPerPage === val) return
    this.pageInfo.startCursor = "0"
    this.pageInfo.endCursor = "0"
    this.setItemsPerPage(val)
    this.paginate(false, false)
  }

  setPage(page: number): void {
    this.$store.commit(`preflights/setPage`, page)
  }

  setItemsPerPage(items: number): void {
    this.$store.commit(`preflights/setItemsPerPage`, items)
  }
}
