


















import Vue from "vue"
import { Component } from "vue-property-decorator"
import NiPage from "@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue"

@Component({
  components: {
    NiPage,
  },
})
export default class Setup extends Vue {
  title = "Troubleshooting"
  troubleshootingItems = [
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/Quick+Start+Guide",
      title: "Quick Start Guide",
      subtitle: "A basic setup guide for working with CAT for the first time",
    },
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/Preflight",
      title: "Preflight",
      subtitle: "How to test out new SDK configurations or BSDK libraries",
    },
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/DNS+Setup",
      title: "DNS Setup",
      subtitle: "How to setup dns on multiple types of devices",
    },
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/Networking+Workarounds",
      title: "Networking Workarounds",
      subtitle: "Check this if your device(s) are not respecting the CAT DNS settings on your device",
    },
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/No+Android+Logs+-+Preflight+Workaround",
      title: "Android Debug Logs Disabled",
      subtitle: "A guide on how to acccess debug logs via network calls when logging on a testing app is locked down",
    },
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/Multiple+CAT+Test+Sessions",
      title: "Split Test Sessions",
      subtitle: "An explanation and guide on why split test sessions may be occurring and how to fix it",
    },
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/Throttling+with+Charles",
      title: "Throttling Network Traffic",
      subtitle: "Instructions on how to throttle network traffic via charles proxy",
    },
    {
      link: "https://adlm.nielsen.com/confluence/display/SOL/DOT+CLI+and+iOS+wireless+debugging",
      title: "Apple TV / iOS Wireless debugging",
      subtitle: "Instructions on how to wirelessly access debug logs from devices that cannot use usb",
    },
  ]

  created() {
    //@ts-ignore
    this.$gtag.pageview("/troubleshooting")
  }
}
