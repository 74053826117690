






























import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Preflight } from "@/generated/graphql"

@Component
export default class DeletePreflight extends Vue {
  @Prop({ default: () => ({}) })
  preflight!: Preflight

  dialog = false

  deletePreflight(preflight: Preflight): void {
    this.$store
      .dispatch("preflights/delete", parseInt(preflight.id))
      .then(({ message }) => {
        emitAlert(EVENTS.GLOBAL_SUCCESS, message)
      })
      .catch(({ message }) => {
        emitAlert(EVENTS.GLOBAL_ERROR, message)
      })
      .finally(() => (this.dialog = false))
  }
}
