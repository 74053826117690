import actions from "./actions.active-sessions"
import getters from "./getters.active-sessions"
import mutations from "./mutations.active-sessions"
import { Maybe, Scalars } from "@/generated/graphql"

export interface ActiveSessionsState {
  sessions: Maybe<Scalars["Json"]>
  loading: boolean
  connection: any
}

export const state: ActiveSessionsState = {
  sessions: [],
  loading: false,
  connection: null,
}

const ActiveSessions = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
}

export default ActiveSessions
