


























































import Vue from "vue"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"
import localTime from "@/components/common/local_time.vue"
import catAvatar from "@/components/comments/avatar.vue"
import {
  Comment,
  CommentMutationMutationVariables,
  DeleteCommentMutationInput,
  Maybe,
  Scalars,
  User,
} from "@/generated/graphql"

@Component({
  components: {
    localTime,
    catAvatar,
  },
})
export default class CommentView extends Vue {
  @Prop({ default: () => "" })
  type!: Maybe<Scalars["String"]>

  @Prop({ default: () => ({}) })
  comment!: Comment

  @Prop({ default: () => "50" })
  avatarSize!: string

  isEdit = false

  get currentUser(): User {
    return this.$store.getters["currentUser/currentUser"]
  }

  get catId(): string | undefined {
    return this.$store.getters["comments/catId"]
  }

  get message(): string | undefined {
    return this?.comment?.message?.replace(/http(s)?:\/\/[a-zA-Z0-9&#=./\-?_]+/gi, match => {
      return `<a href="${match}">${match}</a>`
    })
  }

  get classes(): string | undefined {
    let classes
    switch (this.type) {
      case "ping":
        classes = "comment-wrapper-ping px-4 my-4"
        break
      case "testSession":
        classes = "comment-wrapper-ts px-2 my-1 py-0"
        break
    }

    return classes
  }

  get commentDetailsClass(): string | undefined {
    let classes
    switch (this.type) {
      case "ping":
        classes = "comment-details ping-comment-icons"
        break
      case "testSession":
        classes = "comment-details ts-comment-icons"
        break
      default:
        classes = "ts-comment-details"
    }
    return classes
  }

  editComment() {
    this.isEdit = true
    this.$gtag.event("edit_comment", {
      event_category: "comments",
      event_label: "Edit Comment Click",
      value: this.catId,
    })
  }

  updateComment(payload: CommentMutationMutationVariables): void {
    this.$store.dispatch("comments/updateComment", payload).then(() => {
      this.isEdit = false
    })

    this.$gtag.event("update_comment", {
      event_category: "comments",
      event_label: "Update Comment",
      value: this.catId,
    })
  }

  deleteComment(payload: DeleteCommentMutationInput): void {
    this.$store.dispatch("comments/deleteComment", payload).then(() => {
      this.isEdit = false
    })

    this.$gtag.event("delete_comment", {
      event_category: "comments",
      event_label: "Delete Comment",
      value: this.catId,
    })
  }
}
