





















































import Vue from "vue"
import { Prop } from "vue-property-decorator"
import { Component, Watch } from "vue-property-decorator"
import { Maybe, Scalars } from "@/generated/graphql"

// TODO: move type to activeSessions store when ready
interface Filter {
  appId?: Maybe<Scalars["String"]>
  myLocation?: boolean
  product?: string[]
}

@Component
export default class ActiveSessionsFilterSheet extends Vue {
  @Prop({ default: () => ({}) })
  filter!: Filter

  sheet = false
  availableProducts = [
    { name: "Cloud", value: "Cloud" },
    { name: "S2S", value: "Audit" },
    { name: "App", value: "App" },
    { name: "Browser", value: "Browser" },
  ]

  @Watch("filter")
  handler(value: Filter): void {
    this.$emit("filter", value)
    this.$gtag.event("filter", {
      event_category: "active_session_table",
      event_label: "Filter Change",
    })
  }
}
