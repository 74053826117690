var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"accent","dark":""},on:{"click":_vm.trackFeature}},on),[_c('v-icon',[_vm._v("filter_list")]),_vm._v(" Filter ")],1)]}}])},[_c('v-card',[_c('v-container',{staticClass:"grid-list-md page"},[_c('v-form',{attrs:{"v-model":_vm.router}},[_c('v-row',{attrs:{"justify-space-between":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"slot":"activator","value":_vm.fromDate,"label":"Filter From Date","prepend-icon":"event","readonly":""},slot:"activator"},on))]}}])},[_c('v-date-picker',{ref:"calendar",staticClass:"from-calendar",attrs:{"value":_vm.fromDate,"max":_vm.currentDate,"no-title":"","scrollable":""},on:{"change":_vm.updateFromDate}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"slot":"activator","value":_vm.toDate,"label":"Filter To Date","prepend-icon":"event","readonly":""},slot:"activator"},on))]}}])},[_c('v-date-picker',{staticClass:"to-calendar",attrs:{"value":_vm.toDate,"max":_vm.currentDate,"no-title":"","scrollable":""},on:{"change":_vm.updateToDate}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-autocomplete',{attrs:{"id":"user-input","items":_vm.users,"no-data-text":"No Users Found","item-text":"name","item-value":"id","cache-items":"","clear-icon":"clear","clearable":"","label":"Filter by Users..."},on:{"input":_vm.updateUser,"click:clear":_vm.clearUser}})],1),_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-text-field',{attrs:{"id":"search-term-input","value":_vm.searchTerm,"append-icon":"search","clear-icon":"clear","clearable":"","placeholder":"Search...","type":"text"},on:{"click:clear":_vm.clearSearch,"input":_vm.updateSearchTerm}})],1),_c('v-flex',{attrs:{"xs12":"","md1":"","pt-3":"","pl-2":""}},[_c('v-tooltip',{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"clear-filter",attrs:{"slot":"activator","icon":"","dark":""},on:{"click":_vm.clearFilter},slot:"activator"},on),[_c('v-icon',[_vm._v("restore")])],1)]}}])},[_c('span',[_vm._v("Clear Filters")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }