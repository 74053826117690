import { sanatize } from "@/helpers/filter_chips.helper"
import { TestSessionsState } from "./test-sessions"

export default {
  currentDate(state: TestSessionsState) {
    return state.currentDate
  },
  filterChips(state: TestSessionsState) {
    const filters = {
      userId: state.userId,
      search: state.searchTerm,
      from: state.fromDate,
      to: state.toDate,
    }

    return sanatize(filters, state.users)
  },
  fromDate(state: TestSessionsState) {
    return state.fromDate
  },
  loading(state: TestSessionsState) {
    return state.loading
  },
  sessions(state: TestSessionsState) {
    return state.sessions
  },
  reasons(state: TestSessionsState) {
    return state.reasons
  },
  page(state: TestSessionsState) {
    return state.page
  },
  itemsPerPage(state: TestSessionsState) {
    return state.itemsPerPage
  },
  pageInfo(state: TestSessionsState) {
    return state.pageInfo
  },
  totalCount(state: TestSessionsState) {
    return state.totalCount
  },
  searchTerm(state: TestSessionsState) {
    return state.searchTerm
  },
  toDate(state: TestSessionsState) {
    return state.toDate
  },
  userId(state: TestSessionsState) {
    return state.userId
  },
  users(state: TestSessionsState) {
    return state.users
  },
  searchUser(state: TestSessionsState) {
    return state.searchUser
  },
}
