import Vue from "vue"
import { Skeleton, Buttons } from "@nielsen-media/components"
import "@nielsen-media/components/lib/theme.css"
import VueTimeago from "vue-timeago"
import VueClipboard from "vue-clipboard2"
// import '@/assets/theme.scss';
import "@nielsen-media/nlsn-vue-ui/lib/mixins"

Vue.use(Skeleton)
Vue.use(Buttons)
Vue.use(VueClipboard)
Vue.use(VueTimeago, { name: "Timeago" })
