import actions from "./actions.comments"
import getters from "./getters.comments"
import mutations from "./mutations.comments"
import { Comment } from "@/generated/graphql"

export interface CommentsState {
  catId: string | undefined
  commentCount: number
  tsComments: Comment[]
  pingComments: Comment[]
  message: string | undefined
  loading: boolean
  loadMoreComments: boolean
}

export const state: CommentsState = {
  catId: "",
  commentCount: 5,
  tsComments: [],
  pingComments: [],
  message: "",
  loading: false,
  loadMoreComments: false,
}

const Comments = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
}

export default Comments
