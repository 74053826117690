import { ActiveSessionsState } from "./active-sessions"
import { Maybe, Scalars } from "@/generated/graphql"

export default {
  addSession(state: ActiveSessionsState, session: Maybe<Scalars["Json"]>) {
    state.sessions.push(session)
  },
  replaceSessions(state: ActiveSessionsState, sessions: Maybe<Scalars["Json"]>) {
    state.sessions = sessions
  },
  setConnection(state: ActiveSessionsState, connection: any) {
    state.connection = connection
  },
  setLoading(state: ActiveSessionsState, loading: boolean) {
    state.loading = loading
  },
}
