








import Vue from "vue"
import { Component } from "vue-property-decorator"
import catStreamViewChooser from "@/components/stream/view_chooser.vue"
import { TestSession } from "@/generated/graphql"

@Component({
  components: {
    catStreamViewChooser,
  },
})
export default class HeaderMenu extends Vue {
  get testSession(): TestSession {
    return this.$store.getters["livestream/testSession"]
  }
}
