import Vue from "vue"
import VueI18n, { LocaleMessages } from "vue-i18n"
import * as Sentry from "@sentry/vue"
import messages from "@nielsen-media/nlsn-language/dist/en.json"

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// store the VueI18n instance in a variable
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  missing: (locale, key) => {
    if (process.env.VUE_APP_SENTRY_DSN) {
      Sentry.captureMessage(`missing ${key} for ${locale}`)
    }
  },
})

// merge the locales on top of your application locales
i18n.mergeLocaleMessage("en", messages)

export default i18n
