
























import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import catDeletePreflight from "@/components/preflight/delete_preflight.vue"
import catPreflightDialog from "@/components/preflight/dialog.vue"
import { Preflight, User } from "@/generated/graphql"

@Component({
  components: {
    catPreflightDialog,
    catDeletePreflight,
  },
})
export default class PreflightMenu extends Vue {
  @Prop({
    default: () => ({
      user: {},
    }),
  })
  item!: Preflight

  get currentUser(): User {
    return this.$store.getters["currentUser/currentUser"]
  }
}
