import { ActiveSessionsState } from "./active-sessions"

export default {
  all(state: ActiveSessionsState) {
    return state.sessions
  },
  loading(state: ActiveSessionsState) {
    return state.loading
  },
}
