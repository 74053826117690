import { TsHistoryState } from "./ts-history"

export default {
  loading(state: TsHistoryState) {
    return state.loading
  },
  itemsPerPage(state: TsHistoryState) {
    return state.itemsPerPage
  },
  page(state: TsHistoryState) {
    return state.page
  },
  pageInfo(state: TsHistoryState) {
    return state.pageInfo
  },
  totalCount(state: TsHistoryState) {
    return state.totalCount
  },
  history(state: TsHistoryState) {
    return state.history
  },
}
