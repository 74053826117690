import centrifuge from "@/services/centrifugo_base.service"
import { client } from "@/services/apollo.service"
import { getPreflightIndexById } from "./preflight"
import UPDATE_PREFLIGHT_MUTATION from "@/graphql/update_preflight_mutation.gql"
import PREFLIGHTS_QUERY from "@/graphql/preflights.gql"
import PF_TYPES_QUERY from "@/graphql/preflight_types.gql"
import AUTOCOMPLETE_ITEMS_QUERY from "@/graphql/query_preflight_autocomplete_items.gql"
import DELETE_PREFLIGHT_MUTATION from "@/graphql/delete_preflight_mutation.gql"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Commit, Dispatch } from "vuex"
import { PreflightState } from "./preflight"
import { PreFlightsQueryVariables, UpdatePreflightMutationMutationVariables } from "@/generated/graphql"

export default {
  connect({ dispatch, commit, state }: { commit: Commit; dispatch: Dispatch; state: PreflightState }) {
    if (state.connection) return
    dispatch("loadPreflightTypes")
    dispatch("loadIdentifier")

    const conn = centrifuge.subscribe("cat:preflight", message => {
      dispatch("handleMessages", message.data)
    })
    commit("setConnection", conn)
  },

  disconnect({ commit }: { commit: Commit }) {
    commit("setConnection", null)
  },

  handleMessages({ commit }: { commit: Commit }, { type, data }: { type: string; data: string }) {
    if (type === "update") {
      commit("update", data)
    }
  },

  async paginate({ commit }: { commit: Commit }, payload: PreFlightsQueryVariables) {
    commit("setLoading", true)
    try {
      const response = await client.query({
        query: PREFLIGHTS_QUERY,
        fetchPolicy: "no-cache",
        variables: payload,
      })

      const { nodes, pageInfo, totalCount } = response.data.preflights
      commit("setPreflights", nodes)
      commit("setPageInfo", pageInfo)
      commit("setTotalCount", totalCount)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },

  async loadIdentifier({ commit }: { commit: Commit }) {
    try {
      const response = await client.query({
        query: AUTOCOMPLETE_ITEMS_QUERY,
      })
      commit("setIdentifier", response.data.preflightAutocompleteItems.items)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
  },

  toggleStatus({ state, dispatch }: { state: PreflightState; dispatch: Dispatch }, id: string) {
    const i = getPreflightIndexById(state.preflights, id)
    const preflight = Object.assign(state.preflights[i], {
      active: !state.preflights[i].active,
    })
    dispatch("update", preflight)
  },

  async update(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    preflight: UpdatePreflightMutationMutationVariables
  ) {
    commit("setLoading", true)

    try {
      const response = await client.mutate({
        mutation: UPDATE_PREFLIGHT_MUTATION,
        variables: {
          id: preflight.id,
          active: preflight.active,
          url: preflight.url,
          body: preflight.body,
          networkLogging: preflight.networkLogging,
          apid: preflight.apid,
          pfType: preflight.pfType,
          title: preflight.title,
          identifier: preflight.identifier,
          ip: preflight.ip,
        },
      })

      if (response.data.updatePreflight.status == "success") {
        commit("addPreflight", [response.data.updatePreflight.preflight, preflight.id])
        dispatch("paginate", { first: 10, after: 0, orderBy: "id desc" })
        commit("setPage", 1)
        commit("setItemsPerPage", 10)
        const { message } = response.data.updatePreflight
        emitAlert(EVENTS.GLOBAL_SUCCESS, message)
      }
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },

  async delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
    commit("setLoading", true)
    try {
      const response = await client.mutate({
        mutation: DELETE_PREFLIGHT_MUTATION,
        variables: {
          id: id,
        },
      })

      const { status, message } = response.data.deletePreflight
      if (status === "success") {
        commit("setLoading", false)
        commit("removePreflight", id)
        dispatch("paginate")
        emitAlert(EVENTS.GLOBAL_SUCCESS, message)
      }
      return response.data
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },

  async loadPreflightTypes({ commit }: { commit: Commit }) {
    commit("setLoading", true)
    try {
      const response = await client.query({
        query: PF_TYPES_QUERY,
      })

      commit("setPfTypes", response.data.pfTypes)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },
}
