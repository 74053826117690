

















import Vue from "vue"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"
import CatAvatar from "@/components/comments/avatar.vue"
import { CommentMutationMutationVariables, Maybe, Scalars, User } from "@/generated/graphql"

@Component({
  components: {
    CatAvatar,
  },
})
export default class NewComment extends Vue {
  @Prop({ default: () => "" })
  type!: Maybe<Scalars["String"]>

  @Prop({ default: () => undefined })
  pingId!: Maybe<Scalars["String"]>

  @Prop({ default: () => "50" })
  avatarSize!: string

  @Prop({ default: () => "" })
  catId!: string

  get message(): string {
    return this.$store.getters["comments/message"]
  }

  get currentUser(): User {
    return this.$store.getters["currentUser/currentUser"]
  }

  get classes(): string | undefined {
    let classes
    switch (this.type) {
      case "ping":
        classes = "comment-wrapper-ping px-4 my-4"
        break
      case "testSession":
        classes = "comment-wrapper-ts px-2 my-1 py-2"
        break
    }
    return classes
  }

  addComment(payload: CommentMutationMutationVariables): void {
    this.$store.dispatch("comments/addComment", payload)
  }

  updateMessage(payload: string): void {
    this.$store.commit("comments/updateMessage", payload)
  }
}
