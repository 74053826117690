import centrifuge from "@/services/centrifugo_base.service"
import { client } from "@/services/apollo.service"
import router from "@/router"
import PING_QUERY from "@/graphql/ping_query.gql"
import DESCRIPTIONS_QUERY from "@/graphql/descriptions_query.gql"
import SAVE_DESCRIPTION_MUTATION from "@/graphql/save_description.gql"
import TEST_SESSION_QUERY from "@/graphql/test_session_query.gql"
import CLAIM_OR_UNCLAIM_TEST_SESSION_MUTATION from "@/graphql/claim_or_unclaim_session_mutation.gql"
import UPDATE_TEST_SESSION_MUTATION from "@/graphql/update_test_session_mutation.gql"
import PING_COMMENTS_QUERY from "@/graphql/ping_comments.gql"
import { ROUTES } from "@/constants"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Commit, Dispatch } from "vuex"
import { LivestreamState } from "./livestream"
import { ClaimOrUnclaimTestSessionMutationInput, UpdateTestSessionMutationMutationVariables } from "@/generated/graphql"

export interface LoadPingDetailPayload {
  condensed?: boolean
  id: string
  focusedId?: number
}

export default {
  connect({ commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: LivestreamState }, catId: string) {
    if (!state.catId || state.catId !== catId) {
      commit("setCatId", catId)
      dispatch("cleanup")
      dispatch("loadTestSession", catId)
    }

    if (state.descriptions.length === 0) {
      dispatch("loadDescription")
    }
    const conn = centrifuge.subscribe(`cat:stream.${catId}`, message => {
      dispatch("handleMessages", message.data)
    })

    commit("setConnection", conn)
    dispatch("presence")
  },

  disconnect({ commit, state }: { commit: Commit; state: LivestreamState }) {
    if (!state.connection) return
    state.connection.unsubscribe()
    state.connection.removeAllListeners()
    commit("setConnection", null)
  },

  cleanup({ commit }: { commit: Commit }) {
    commit("setPings", [])
    commit("setPing", { ping: { details: {}, comments: [] } })
    commit("setPresence", [])
    commit("setSessionUser", null)
  },

  handleMessages({ commit }: { commit: Commit }, { type, data }: { type: string; data: any }) {
    const displayMessage = (text: string) => emitAlert(EVENTS.GLOBAL_SUCCESS, text)

    if (type === "ping") {
      commit("addPing", data)
    }

    if (type === "new-app") {
      commit("addNewApp", data)
    }

    if (type === "message") {
      displayMessage(data.message)
    }

    if (type === "delete") {
      router.push({ name: ROUTES.DASHBOARD.name })
      displayMessage(data.message)
    }

    if (type === "ended") {
      commit("deactivateTestSession", data)
      displayMessage(data.message.text)
    }

    if (type === "claimed") {
      commit("setSessionUser", data.user)
      displayMessage(data.message.text)
    }

    if (type === "unclaimed") {
      commit("setSessionUser", null)
      displayMessage(data.message.text)
    }
  },

  presence({ commit, state }: { commit: Commit; state: LivestreamState }) {
    const conn = state.connection
    if (!conn) return

    conn
      .presence()
      .then(({ presence }: { presence: any }) =>
        Object.keys(presence).forEach(u => commit("addUserToRoom", presence[u]))
      )
      .catch((e: Error) => console.log(e, conn))

    conn.on("join", (evt: any) => commit("addUserToRoom", evt.info))
    conn.on("leave", (evt: any) => commit("removeUserFromRoom", evt.info.client))
  },

  async loadTestSession({ commit }: { commit: Commit }, catId: string) {
    commit("setLoading", true)
    try {
      const res = await client.query({
        query: TEST_SESSION_QUERY,
        variables: {
          catId: catId,
        },
      })

      const { existingPings, user, ...testSession } = res.data.testSession
      testSession.user = user
      commit("setTestSession", testSession)
      commit("setSessionUser", user)
      commit("setPings", existingPings)
      testSession.sessionType == "audit" ? commit("setFilterKey", { key: "showClock", value: true }) : null
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Could not load test session")
    }
    commit("setLoading", false)
  },

  async actions({ dispatch }: { dispatch: Dispatch }, payload: ClaimOrUnclaimTestSessionMutationInput) {
    try {
      const res = await client.mutate({
        mutation: CLAIM_OR_UNCLAIM_TEST_SESSION_MUTATION,
        variables: {
          cat_id: payload.catId,
          type_of_action: payload.typeOfAction,
        },
      })
      dispatch("loadTestSession", payload.catId)
      if (res.data.claimOrUnclaimTestSession.status == "error") {
        throw res.data.claimOrUnclaimTestSession
      }
      return res.data.claimOrUnclaimTestSession
    } catch (res) {
      emitAlert(EVENTS.GLOBAL_ERROR, res.message)
      return res
    }
  },

  async updateTestSession({ commit }: { commit: Commit }, payload: UpdateTestSessionMutationMutationVariables) {
    try {
      const res = await client.mutate({
        mutation: UPDATE_TEST_SESSION_MUTATION,
        variables: {
          id: payload.id,
          name: payload.name,
        },
      })

      commit("setTestSession", res.data.updateTestSession.testSession)
      return res.data.updateTestSession
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
  },

  async loadDescription({ commit }: { commit: Commit }) {
    try {
      const res = await client.query({
        query: DESCRIPTIONS_QUERY,
      })
      commit("setDescriptions", res.data.descriptions)
      return res.data.descriptions
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
  },

  async saveDescription({ commit, getters }: { commit: Commit; getters: any }, description: string) {
    if (!getters.canUpdateDescriptions) {
      return emitAlert(EVENTS.GLOBAL_ERROR, "You cannot add action to Ping that's deactivated or not claimed.")
    }
    try {
      const res = await client.mutate({
        mutation: SAVE_DESCRIPTION_MUTATION,
        variables: description,
      })

      commit("updatePing", description)
      emitAlert(EVENTS.GLOBAL_SUCCESS, res.data.saveDescription.message)
      return res.data.saveDescription
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
  },

  closePingDetail({ commit }: { commit: Commit }) {
    commit("setPing", { ping: { details: {}, comments: [] } })
  },

  async loadPingDetail({ state, commit }: { commit: Commit; state: LivestreamState }, payload: LoadPingDetailPayload) {
    if (state.selectedPing.details && state.selectedPing.details.id == payload.id) return
    commit("setPingLoading", true)

    const focusedId = payload.focusedId ? payload.focusedId : payload.id

    let ping = {
      details: {},
      filterType: "",
      comments: [],
    }

    commit("setPing", { ping, focusedId })
    try {
      const pingDetails = await client.query({
        query: PING_QUERY,
        variables: {
          id: payload.id,
        },
      })

      const pingComments = await client.query({
        query: PING_COMMENTS_QUERY,
        variables: {
          commentableId: parseInt(payload.id),
        },
      })

      ping = {
        details: pingDetails.data.ping.details,
        comments: pingComments.data.pingComments.nodes,
        filterType: pingDetails.data.ping.filterType,
      }

      commit("setPing", { ping, focusedId })
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Could not load ping details")
    }
    commit("setPingLoading", false)
  },
}
