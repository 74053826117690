




























































































































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { Maybe, Scalars, Preflight } from "@/generated/graphql"

@Component
export default class PreflightDialog extends Vue {
  @Prop({ default: () => false })
  edit!: boolean

  @Prop({
    default: () => ({
      id: null,
      userId: null,
      apid: "",
      active: false,
      pfType: undefined,
      url: "",
      body: "",
      networkLogging: false,
    }),
  })
  preflightProps!: Preflight

  preflight: Preflight = {
    id: "",
    userId: -1,
    apid: "",
    active: false,
    pfType: undefined,
    url: "",
    body: "",
    networkLogging: false,
    locked: false,
    user: { email: "", id: "" },
  }

  dialog = false

  created() {
    Object.assign(this.preflight, this.preflightProps)
  }

  get pfTypes(): Maybe<Scalars["Json"]> {
    return this.$store.getters["preflights/get"]
  }

  get autocompleteItems(): any {
    return this.$store.getters["preflights/identifierAutocompleteItems"]
  }

  validate(message: string | undefined): boolean | string {
    //@ts-ignore
    return [v => !!v || `${message} is required`]
  }

  async save(): Promise<void> {
    //@ts-ignore
    if (!this.$refs.form.validate()) return
    try {
      //@ts-ignore
      this.preflight.id = parseInt(this.preflight.id)
      await this.$store.dispatch("preflights/update", this.preflight)
      this.dialog = false
    } catch {
      this.dialog = true
    }
  }
}
