import { ROUTES } from "@/constants"
export const navigation = {
  top: [
    { heading: "Core" },
    {
      icon: "dashboard",
      text: ROUTES.DASHBOARD.name,
      route: ROUTES.DASHBOARD.path,
    },
    {
      icon: "data_usage",
      text: ROUTES.TEST_SESSIONS.name,
      route: ROUTES.TEST_SESSIONS.path,
    },
    {
      icon: "dns",
      text: ROUTES.SETUP.name,
      route: ROUTES.SETUP.path,
    },
    {
      icon: "help_outline",
      text: ROUTES.TROUBLESHOOTING.name,
      route: ROUTES.TROUBLESHOOTING.path,
    },
  ],
}
