import Vue from "vue"
import Vuetify from "vuetify/lib"
import * as VComponents from "vuetify/lib/components"

import "material-design-icons-iconfont/dist/material-design-icons.css"
import "@mdi/font/css/materialdesignicons.css"
import colors from "vuetify/es5/util/colors"

Vue.use(Vuetify, {
  components: { ...VComponents },
})

import { Skeleton, Buttons, Chips } from "@nielsen-media/components"
Vue.use(Skeleton)
Vue.use(Buttons)
Vue.use(Chips)
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      dark: {
        primary: colors.blueGrey.lighten3,
        secondary: colors.orange.base,
        transparent: colors.shades.transparent,
        accent: colors.purple.darken1,
        error: colors.red.darken1,
        blue: colors.blue,
        black: colors.shades.black,
        red: colors.red,
        green: colors.green,
      },
    },
    dark: true,
  },
})
