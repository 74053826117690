






import Vue from "vue"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"
import { Maybe, Scalars } from "@/generated/graphql"

// TODO: move type to activeSessions store when ready
interface Filter {
  appId?: Maybe<Scalars["String"]>
  myLocation?: boolean
  product?: string[]
}

interface DisplayFilter {
  appId?: string
  myLocation?: boolean
  product?: string
}

@Component
export default class ActiveFilter extends Vue {
  @Prop({ default: () => ({}) })
  filter!: Filter

  get shownFilter(): DisplayFilter {
    const displayFilter: DisplayFilter = {}
    for (const key in this.filter) {
      //@ts-ignore
      if (this.filter[key] || (this.filter[key] && this.filter[key].length > 0)) {
        //@ts-ignore
        if (Array.isArray(this.filter[key])) {
          // array values to string
          //@ts-ignore
          if (this.filter[key].join(", ").length) {
            //@ts-ignore
            displayFilter[key] = this.filter[key].join(", ")
          }
        } else {
          //@ts-ignore
          displayFilter[key as keyof DisplayFilter] = this.filter[key as keyof Filter]
        }
      }
    }
    return displayFilter
  }
}
