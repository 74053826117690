import { client } from "@/services/apollo.service"
import USERS_SEARCH from "@/graphql/users_search.gql"
import SEARCH_SESSIONS_QUERY from "@/graphql/search_sessions.gql"
import REASONS_QUERY from "@/graphql/archived_reasons.gql"
import UPDATE_TEST_SESSION_MUTATION from "@/graphql/update_test_session_mutation.gql"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Commit, Dispatch } from "vuex"
import { TestSessionsState } from "./test-sessions"
import { UpdateTestSessionMutationInput } from "@/generated/graphql"

const validDateSearch = (fromDate: string, toDate: string) => {
  const fdate = new Date(fromDate)
  const tdate = new Date(toDate)
  if (fdate > tdate) return false
  return true
}

export default {
  async paginate({ commit, state }: { commit: Commit; state: TestSessionsState }, isPrevious: boolean) {
    commit("setLoading", true)

    const payload: any = {
      from: state.fromDate,
      to: state.toDate,
      userId: state.userId,
      searchTerm: state.searchTerm,
      orderBy: "id desc",
    }

    if (isPrevious && state.pageInfo.startCursor) {
      payload.before = parseInt(state.pageInfo.startCursor)
      payload.last = state.itemsPerPage
    } else if (state.pageInfo.endCursor) {
      payload.after = parseInt(state.pageInfo.endCursor)
      payload.first = state.itemsPerPage
    }

    try {
      const response = await client.query({
        query: SEARCH_SESSIONS_QUERY,
        variables: payload,
      })

      const {
        testSessions: { nodes, pageInfo, totalCount } = { nodes: {}, pageInfo: {}, totalCount: {} },
      } = response.data.search

      commit("setSessions", nodes)
      commit("setPageInfo", pageInfo)
      commit("setTotalCount", totalCount)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },

  async loadReasons({ commit }: { commit: Commit }) {
    commit("setLoading", true)
    try {
      const response = await client.query({
        query: REASONS_QUERY,
      })
      commit("setReasons", response.data.reasons)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },

  async loadUsers({ commit }: { commit: Commit }, search: { userSearch: string }) {
    commit("setLoading", true)
    try {
      const response = await client.query({
        query: USERS_SEARCH,
        variables: {
          userSearch: search.userSearch,
        },
      })

      commit("setUsers", response.data.search.users)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },

  async updateTestSession({ commit }: { commit: Commit }, details: UpdateTestSessionMutationInput) {
    commit("setLoading", true)
    try {
      const response = await client.mutate({
        mutation: UPDATE_TEST_SESSION_MUTATION,
        variables: {
          id: details.id,
          archived: details.archived,
          archived_reason: details.archivedReason,
        },
      })
      commit("setLoading", false)
      return response.data.updateTestSession
    } catch (_) {
      commit("setLoading", false)
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
  },

  clearSearch({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    commit("clearSearch")
    dispatch("paginate", false)
  },

  clearUser({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    commit("clearUser")
    dispatch("paginate", false)
  },

  clearFilter({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    commit("clearFilter")
    dispatch("paginate", false)
  },

  updateFromDate(
    { commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: TestSessionsState },
    fromDate: string
  ) {
    if (!validDateSearch(fromDate, state.toDate)) {
      return emitAlert(EVENTS.GLOBAL_WARNING, "'From date' should not be more recent than 'to date'")
    }
    commit("resetPageInfo")
    commit("updateFromDate", fromDate)
    dispatch("paginate", false)
  },

  updateToDate(
    { commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: TestSessionsState },
    toDate: string
  ) {
    if (!validDateSearch(state.fromDate, toDate)) {
      return emitAlert(EVENTS.GLOBAL_WARNING, "'From date' should not be more recent than 'to date'")
    }
    commit("resetPageInfo")
    commit("updateToDate", toDate)
    dispatch("paginate", false)
  },

  updateSearchTerm({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, searchTerm: string) {
    commit("resetPageInfo")
    commit("updateSearchTerm", searchTerm)
    dispatch("paginate", false)
  },

  updateUser({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, searchUser: string) {
    if (!searchUser) return
    commit("resetPageInfo")
    commit("updateUser", searchUser)
    dispatch("paginate", false)
  },

  updateFilterAndState({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, pagination: any) {
    commit("updateSearchAndFilter", pagination)
    dispatch("paginate", false)
  },
}
