



































































import Vue from "vue"
import { Component } from "vue-property-decorator"
import catMenu from "@/components/common/menu.vue"
import localTime from "@/components/common/local_time.vue"
import { ROUTES } from "@/constants"
import { PageInfo, TestSession } from "@/generated/graphql"

@Component({
  components: {
    catMenu,
    localTime,
  },
})
export default class RecentSessionsTable extends Vue {
  headers = [
    { text: "#", value: "id", align: "left" },
    { text: "Title", value: "title", align: "left", sortable: false },
    { text: "Active", value: "active", align: "left", sortable: false },
    { text: "Created", value: "created_at", align: "left" },
    { text: "Actions", value: "actions", align: "left", sortable: false },
  ]
  dialog1 = false
  livestreamRouteName = ROUTES.STREAM.name

  created(): void {
    const requestPayload = { after: 0, first: 10 }
    this.$store.dispatch(`currentUser/paginateRecentTestSessions`, requestPayload)
  }

  get recentSessions(): TestSession[] {
    return this.$store.getters["currentUser/recentSessions"]
  }

  get itemsPerPage(): number {
    return this.$store.getters["currentUser/itemsPerPage"]
  }

  get page(): number {
    return this.$store.getters["currentUser/page"]
  }

  get pageInfo(): PageInfo {
    return this.$store.getters["currentUser/pageInfo"]
  }

  get totalCount(): number {
    const count = this.$store.getters["currentUser/totalCount"]
    return count > 10 ? count : 10
  }

  get loading(): boolean {
    return this.$store.getters["currentUser/isLoading"]
  }

  paginateRecentTestSessions(isPrevious: boolean): void {
    const requestPayload: any = {}
    if (isPrevious) {
      requestPayload.before = this.pageInfo?.startCursor ? parseInt(this.pageInfo.startCursor) : 0
      requestPayload.last = this.itemsPerPage
    } else {
      requestPayload.after = this.pageInfo?.endCursor ? parseInt(this.pageInfo.endCursor) : 0
      requestPayload.first = this.itemsPerPage
    }
    this.$store.dispatch(`currentUser/paginateRecentTestSessions`, requestPayload)
  }

  stringHumanize(str: string): string | undefined {
    if (str != null) {
      const chars = str.split("_")
      for (let i = 0; i < chars.length; i++) {
        chars[i] = chars[i].charAt(0).toUpperCase() + chars[i].slice(1)
      }
      return chars.join(" ")
    }
  }

  updatePage(pageNumber: number): void {
    if (this.loading) return
    if (this.page === pageNumber) return
    let isPrevious = false

    if (pageNumber < this.page) {
      isPrevious = true
    }

    this.setPage(pageNumber)
    this.paginateRecentTestSessions(isPrevious)
  }

  updateItemsPerPage(val: number): void {
    if (this.itemsPerPage === val) return
    this.pageInfo.startCursor = "0"
    this.pageInfo.endCursor = "0"
    this.setItemsPerPage(val)
    this.paginateRecentTestSessions(false)
  }

  setPage(page: number): void {
    this.$store.commit(`currentUser/setPage`, page)
  }

  setItemsPerPage(items: number): void {
    this.$store.commit(`currentUser/setItemsPerPage`, items)
  }

  updateIdSort(val: string): void {
    const requestPayload = { after: 0, first: 10, orderBy: "id desc" }
    if (val.length) {
      requestPayload.orderBy = "id asc"
      this.$store.dispatch(`currentUser/paginateRecentTestSessions`, requestPayload)
    } else {
      this.$store.dispatch(`currentUser/paginateRecentTestSessions`, requestPayload)
    }
  }
}
