import { client } from "@/services/apollo.service"
import { setCentrifugoJwt } from "@/services/centrifugo_base.service"
import CURRENT_USER_QUERY from "@/graphql/current_user.gql"
import RECENT_SESSIONS_QUERY from "@/graphql/recent_sessions.gql"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Commit } from "vuex"
import { RecentSessionsQueryVariables } from "@/generated/graphql"

export default {
  async paginateRecentTestSessions({ commit }: { commit: Commit }, payload: RecentSessionsQueryVariables) {
    commit("setLoading", true)
    try {
      const response = await client.query({
        query: RECENT_SESSIONS_QUERY,
        fetchPolicy: "no-cache",
        variables: payload,
      })
      const { nodes, totalCount, pageInfo } = response.data.currentUser.testSessions

      commit("setSessions", nodes)
      commit("setPageInfo", pageInfo)
      commit("setTotalCount", totalCount)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },

  async load({ commit }: { commit: Commit }) {
    try {
      const response = await client.query({
        query: CURRENT_USER_QUERY,
      })

      const currentUser = response.data.currentUser
      commit("setUser", currentUser)
      setCentrifugoJwt(currentUser.centrifugoToken)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
  },
}
