import { client } from "@/services/apollo.service"
import HISTORY_QUERY from "@/graphql/audits.gql"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Commit } from "vuex"
import { AuditsQueryVariables } from "@/generated/graphql"

export default {
  async loadHistory({ commit }: { commit: Commit }, payload: AuditsQueryVariables) {
    commit("setLoading", true)
    try {
      const response = await client.query({
        query: HISTORY_QUERY,
        variables: payload,
      })

      const { nodes, pageInfo, totalCount } = response.data.audits

      commit("setHistory", nodes)
      commit("setPageInfo", pageInfo)
      commit("setTotalCount", totalCount)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Something odd happened")
    }
    commit("setLoading", false)
  },
}
