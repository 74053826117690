






















































































import { User } from "@/generated/graphql"
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { Getter } from "vuex-class"

@Component({
  components: {},
  props: {},
  methods: {
    logoutHandler() {
      // @ts-ignore
      this.$auth.logout()
    },
  },
})
export default class AppBar extends Vue {
  @Prop() title!: string
  @Prop() icon!: string
  @Prop() apps!: {}[]

  @Getter(`currentUser/currentUser`)
  user!: User

  imgError = false
  get currentUser() {
    return this.user
  }
}
