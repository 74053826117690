import { CommentsState } from "./comments"
import { Comment } from "@/generated/graphql"

export default {
  setLoading(state: CommentsState, loading: boolean) {
    state.loading = loading
  },
  setTsComments(state: CommentsState, comments: Comment[]) {
    state.tsComments = comments
  },
  setPingComments(state: CommentsState, comments: Comment[]) {
    state.pingComments = comments
  },
  addComment(state: CommentsState, comment: Comment) {
    state.tsComments.unshift(comment)
  },
  setCatId(state: CommentsState, catId: string) {
    state.catId = catId
  },
  updateComment(state: CommentsState, comment: Comment) {
    const index = state.tsComments.findIndex(c => c.id == comment.id)
    state.tsComments[index] = comment
  },
  deleteComment(state: CommentsState, id: string) {
    const index = state.tsComments.findIndex(c => c.id == id)
    state.tsComments.splice(index, 1)
  },
  updateMessage(state: CommentsState, message: string) {
    state.message = message
  },
  loadMoreComments(state: CommentsState, loadMoreComments: boolean) {
    state.loadMoreComments = loadMoreComments
    state.commentCount = loadMoreComments ? state.commentCount + 5 : 5
  },
}
