import { render, staticRenderFns } from "./fact_headline.vue?vue&type=template&id=bf1fc8da&scoped=true&"
import script from "./fact_headline.vue?vue&type=script&lang=ts&"
export * from "./fact_headline.vue?vue&type=script&lang=ts&"
import style0 from "./fact_headline.vue?vue&type=style&index=0&id=bf1fc8da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf1fc8da",
  null
  
)

export default component.exports