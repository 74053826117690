const map = {
  "DCR Audio Debug": [
    "pings_dcr_debug_flush",
    "pings_dcr_debug_focus",
    "pings_dcr_debug_play",
    "pings_dcr_debug_loadcontentmetadata",
    "pings_dcr_debug_playhead",
    "pings_dcr_debug_loadadmetadata",
    "pings_dcr_debug_stop",
    "pings_dcr_debug_end",
    "pings_dcr_debug_updateott",
    "pings_dcr_debug_sdkinit",
    "pings_dcr_debug_sfinfo",
    "pings_dcr_debug_background",
    "pings_dcr_debug_foreground",
    "invalid_ping",
  ],
  "DCR Audio Network": [
    "pings_dcr_sdkcachedconfig",
    "pings_dcr_sdklib",
    "pings_dcr_appconfig",
    "pings_dcr_audio_view",
    "pings_dcr_audio_timer",
    "pings_dcr_session",
    "invalid_ping",
  ],
  "DCR Static Debug": [
    "pings_dcr_debug_flush",
    "pings_dcr_debug_focus",
    "pings_dcr_debug_paginate",
    "pings_dcr_debug_staticstart",
    "pings_dcr_debug_staticend",
    "pings_dcr_debug_staticplayhead",
    "pings_dcr_debug_loadstaticmetadata",
    "pings_dcr_debug_sdkinit",
    "pings_dcr_debug_sfinfo",
    "pings_dcr_debug_background",
    "pings_dcr_debug_foreground",
    "invalid_ping",
  ],
  "DCR Static Network": [
    "pings_dcr_sdkcachedconfig",
    "pings_dcr_sdklib",
    "pings_dcr_appconfig",
    "pings_dcr_static_view",
    "pings_dcr_static_timer",
    "pings_dcr_static_launch",
    "pings_dcr_session",
    "invalid_ping",
  ],
  "DCR Video Debug": [
    "pings_dcr_debug_flush",
    "pings_dcr_debug_focus",
    "pings_dcr_debug_play",
    "pings_dcr_debug_loadcontentmetadata",
    "pings_dcr_debug_playhead",
    "pings_dcr_debug_loadadmetadata",
    "pings_dcr_debug_stop",
    "pings_dcr_debug_end",
    "pings_dcr_debug_updateott",
    "pings_dcr_debug_sdkinit",
    "pings_dcr_debug_sfinfo",
    "pings_dcr_debug_background",
    "pings_dcr_debug_foreground",
    "invalid_ping",
  ],
  "DCR Video Network": [
    "pings_dcr_sdkcachedconfig",
    "pings_dcr_sdklib",
    "pings_dcr_appconfig",
    "pings_dcr_video_view",
    "pings_dcr_video_timer",
    "pings_dcr_session",
    "invalid_ping",
  ],
  "DTVR Network": [
    "pings_dcr_sdkcachedconfig",
    "pings_dcr_sdklib",
    "pings_dcr_appconfig",
    "pings_dcr_session",
    "pings_dtvr_impression",
    "pings_dtvr_quarter",
    "pings_dtvr_timer",
    "pings_dtvr_tsv",
    "pings_dtvr_view",
    "pings_dtvr_trans",
    "invalid_ping",
  ],
  "DTVR Debug": [
    "pings_dcr_debug_flush",
    "pings_dcr_debug_focus",
    "pings_dcr_debug_play",
    "pings_dcr_debug_stop",
    "pings_dcr_debug_end",
    "pings_dcr_debug_loaddtvrmetadata",
    "pings_dcr_debug_sendid3",
    "pings_dcr_debug_updateott",
    "pings_dcr_debug_sdkinit",
    "pings_dcr_debug_sfinfo",
    "pings_dcr_debug_background",
    "pings_dcr_debug_foreground",
    "invalid_ping",
  ],
  "Track Event": ["pings_dcr_debug_trackevent", "invalid_ping"],
}

const products = (): string[] => Object.keys(map)
const types = (needle: string | string[]): string[] => {
  let types: string[] = []
  if (Array.isArray(needle) && needle.length > 0)
    products().forEach(p => {
      if (needle.includes(p)) {
        //@ts-ignore
        types = [...types, ...map[p]]
      }
    })
  return types
}

export default {
  products,
  types,
  map,
}
