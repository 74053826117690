








import Vue from "vue"
import { Component } from "vue-property-decorator"

@Component
export default class ActiveFilters extends Vue {
  get filterChips(): string[] {
    return this.$store.getters["testSessions/filterChips"]
  }
}
