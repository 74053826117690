








import Vue from "vue"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"

@Component
export default class CatAvatar extends Vue {
  @Prop({ default: () => "" })
  avatarUrl!: string

  @Prop({ default: () => "50" })
  size!: string
}
