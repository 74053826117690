
























import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import pingLabels from "./ping_labels.vue"
import catCondensedPromptModal from "@/components/stream/condensed_prompt_modal.vue"
import catTimestamp from "@/components/common/cat_timestamp.vue"
import pingDescription from "./ping_description.vue"
import { isPlayhead } from "@/helpers/condensed_filter.helper"
import { ROUTES } from "@/constants"
import { LivestreamFilters, LivestreamPing } from "@/store/modules/livestream/livestream"

@Component({
  components: {
    pingLabels,
    catCondensedPromptModal,
    catTimestamp,
    pingDescription,
  },
})
export default class StreamPing extends Vue {
  @Prop({
    default: () => ({
      warning_messages: {},
      error_messages: {},
    }),
  })
  ping!: LivestreamPing

  @Prop({ default: false })
  selected!: boolean

  @Prop({ default: false })
  pingDetailsOpen!: boolean

  showModal = false

  get filters(): LivestreamFilters {
    return this.$store.getters["livestream/filters"]
  }

  get focusedPingId(): number {
    return this.$store.getters["livestream/focusedPingId"]
  }

  get condensedRow(): boolean {
    if (!this.filters.condensed) return false
    return this.ping.condensedInfo && this.ping.condensedInfo.end.id ? true : false
  }

  get labels(): string[] {
    let labels = this.ping.labels
    if (!this.filters.condensed && isPlayhead(this.ping)) {
      const condensedLabel = this.ping.labels[3] ? this.ping.labels[3].indexOf("-") : -1
      if (condensedLabel > -1) {
        labels = [...this.ping.labels]
        labels[3] = labels[3].slice(0, condensedLabel - 1) // necessary as deep changes to props not represented in ui / vuex can't detect
      }
    }
    return labels
  }

  description(): string {
    if (!this.filters.condensed) {
      if (this.ping.condensedInfo) {
        delete this.ping.condensedInfo // necessary as deep changes to props not represented in ui
      }
    }

    if (!this.ping.description && this.ping.condensedInfo && this.ping.condensedInfo.end.description) {
      return this.ping.condensedInfo.end.description + " (end)"
    }

    if (this.ping.description && this.ping.condensedInfo && this.ping.condensedInfo.end.description) {
      return this.ping.description + " (start) | " + this.ping.condensedInfo.end.description + " (end)"
    }

    if (this.ping.description && this.condensedRow) {
      return this.ping.description + " (start)"
    }

    if (this.ping.description) {
      return this.ping.description
    }
    return ""
  }

  commentedOn(): boolean | null {
    let commentedOn = this.ping.commented_on
    if (!this.filters.condensed) {
      if (this.ping.condensedInfo) {
        delete this.ping.condensedInfo // necessary as deep changes to props not represented in ui
      }
    }

    if (!this.ping.commented_on && this.ping.condensedInfo && this.ping.condensedInfo.end.commented_on) {
      commentedOn = this.ping.condensedInfo.end.commented_on
    }

    return commentedOn
  }

  loadPingDetail(condensed: boolean, id: number, focusedId: number) {
    if (!condensed) {
      this.$router
        .replace({
          name: ROUTES.STREAM.name,
          query: {
            pingId: id.toString(),
          },
        })
        .catch(() => ({}))
      this.$store.dispatch("livestream/loadPingDetail", { id, focusedId })
    } else {
      this.showModal = true
    }
  }

  classes(): string {
    let classes = `cat-ping pl-2 ${this.ping.filter_type}`
    if (this.selected) classes = `${classes} cat-ping-highlighted`
    if (Object.keys(this.ping.warning_messages).length > 0) classes = `${classes} cat-ping-warning`
    if (Object.keys(this.ping.error_messages).length > 0) classes = `${classes} cat-ping-error`
    if (this.description()) classes = `${classes} cat-ping-has-description`
    if (this.filters.highlightUserInteractions && (this.description().length || this.commentedOn()))
      classes = `${classes} cat-ping-highlight-description`
    if (this.focusedPingId === this.ping.id) {
      classes = `${classes} focused`
      // this.$refs.ping.scrollIntoView(false);
    }
    return classes
  }

  setModal(show: boolean): void {
    this.showModal = show
  }
}
