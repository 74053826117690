import centrifuge from "@/services/centrifugo_base.service"
import { client } from "@/services/apollo.service"
import ACTIVE_SESSIONS_QUERY from "@/graphql/active_sessions.gql"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Commit } from "vuex"
import { ActiveSessionsState } from "./active-sessions"

export default {
  connect({ commit, state }: { commit: Commit; state: ActiveSessionsState }) {
    if (state.connection) return
    const connection = centrifuge.subscribe("cat:active_sessions", message => {
      commit("replaceSessions", message.data)
    })
    commit("setConnection", connection)
  },

  disconnect({ commit, state }: { commit: Commit; state: ActiveSessionsState }) {
    if (!state.connection) return
    state.connection.unsubscribe()
    commit("setConnection", null)
  },

  async loadActiveSessions({ commit }: { commit: Commit }) {
    commit("setLoading", true)
    try {
      const response = await client.query({
        query: ACTIVE_SESSIONS_QUERY,
      })
      commit("replaceSessions", response.data.activeSessions.testSessions)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Failed loading active sessions")
    }
    commit("setLoading", false)
  },
}
