






































































































import Vue from "vue"
import { Component } from "vue-property-decorator"
import { ROUTES } from "@/constants"
import { User } from "@/generated/graphql"

@Component
export default class SearchForm extends Vue {
  mounted(): void {
    this.loadUsers({ userSearch: "" })
  }

  get currentDate(): string {
    return this.$store.getters["testSessions/currentDate"]
  }

  get filter() {
    return this.$store.getters["testSessions/filter"]
  }

  get fromDate(): string {
    return this.$store.getters["testSessions/fromDate"]
  }

  get page(): number {
    return this.$store.getters["testSessions/page"]
  }

  get itemsPerPage(): number {
    return this.$store.getters["testSessions/itemsPerPage"]
  }

  get searchTerm(): string {
    return this.$store.getters["testSessions/searchTerm"]
  }

  get toDate(): string {
    return this.$store.getters["testSessions/toDate"]
  }

  get userId(): number {
    return this.$store.getters["testSessions/userId"]
  }

  get users(): User[] {
    return this.$store.getters["testSessions/users"]
  }

  get router(): void {
    return this.replaceRoute()
  }

  clearFilter(): void {
    this.$store.dispatch("testSessions/clearFilter")
  }

  clearUser(): void {
    this.$store.dispatch("testSessions/clearUser")
  }

  clearSearch(): void {
    this.$store.dispatch("testSessions/clearSearch")
  }

  loadUsers(users: { [key: string]: string }) {
    this.$store.dispatch("testSessions/loadUsers", users)
  }

  updateFromDate(fromDate: string): void {
    this.$store.dispatch("testSessions/updateFromDate", fromDate)
  }

  updateSearchTerm(searchTerm: string): void {
    this.$store.dispatch("testSessions/updateSearchTerm", searchTerm)
  }

  updateToDate(toDate: string): void {
    this.$store.dispatch("testSessions/updateToDate", toDate)
  }

  updateUser(user: string) {
    this.$store.dispatch("testSessions/updateUser", user)
  }

  replaceRoute(): void {
    this.$router
      .replace({
        name: ROUTES.TEST_SESSIONS.name,
        query: {
          from: this.fromDate,
          to: this.toDate,
          user: this.userId?.toString(),
          search: this.searchTerm,
          page: this.page.toString(),
          rows: this.itemsPerPage?.toString(),
        },
      })
      .catch(() => ({}))
  }

  trackFeature(): void {
    this.$gtag.event("filter", {
      event_category: "test_sessions",
      event_label: "Test Sessions Filter Click",
    })
  }
}
