

























import Vue from "vue"
import { Component } from "vue-property-decorator"
import dnsCheckItem from "@/components/common/dns_check_item.vue"
import NiPage from "@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue"

@Component({
  components: {
    NiPage,
    dnsCheckItem,
  },
})
export default class Setup extends Vue {
  title = "Setup"
  env = process.env.VUE_APP_ENV
  items = [
    "cat.imrworldwide.com",
    "secure-eu.imrworldwide.com",
    "secure-eu-uat.imrworldwide.com",
    "secure-eu-cert.imrworldwide.com",
    "cloudapi.imrworldwide.com",
    "eu-cloudapi.imrworldwide.com",
    "uaid-linkage.imrworldwide.com",
    "eu-uaid-linkage.imrworldwide.com",
    "sandbox-cloudapi.imrworldwide.com",
    "secure-dcr.imrworldwide.com",
    "secure-dcr-cert.imrworldwide.com",
    "secure-us.imrworldwide.com",
    "secure-cert.imrworldwide.com",
    "seccdn-gl.imrworldwide.com",
    "cdn-gl.imrworldwide.com",
    "secure-gl.imrworldwide.com",
    "secure-asts.imrworldwide.com",
    "secure-asts-np.imrworldwide.com",
    "sdk.imrworldwide.com",
    "amzn-audit.imrworldwide.com",
    "secure-qat1.imrworldwide.com",
  ]

  created() {
    //@ts-ignore
    this.$gtag.pageview("/setup")
  }
}
