





















































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import catCondensedPromptModal from "./condensed_prompt_modal.vue"
import { Maybe, TestSession, Scalars } from "@/generated/graphql"
import { LivestreamPing } from "@/store/modules/livestream/livestream"

@Component({
  components: {
    catCondensedPromptModal,
  },
})
export default class PingDescription extends Vue {
  @Prop({ default: () => ({}) })
  ping!: LivestreamPing

  @Prop({ default: () => "" })
  description!: string

  @Prop({ default: () => false })
  condensedRow!: boolean

  @Prop({ default: () => false })
  commentedOn!: boolean

  @Prop({ default: false })
  pingDetailsOpen!: boolean

  showModal = false
  showDropdown = false
  clearDescription = false
  select = ""

  get activeTestSession(): TestSession {
    return this.$store.getters["livestream/activeTestSession"]
  }

  get descriptions(): Maybe<Scalars["String"]> {
    return this.$store.getters["livestream/descriptions"]
  }

  get testSession(): TestSession {
    return this.$store.getters["livestream/testSession"]
  }

  get iconColor(): string {
    return this.description ? "yellow" : "white"
  }

  get wrapperClasses(): string {
    const cssClass = "cat-ping-description-wrapper"
    return this.pingDetailsOpen ? cssClass + "-selected" : cssClass
  }

  get iconClasses(): string {
    return this.commentedOn ? "cat-ping-description cat-ping-arrow" : "cat-ping-description"
  }

  setModal(show: boolean, clear: boolean): void {
    this.showModal = show
    this.clearDescription = clear
  }

  saveDescription(id: number, action: string): void {
    const description = {
      id: id.toString(),
      value: this.select ? this.select : "",
      ts_id: this.testSession.id.toString(),
    }
    if (action == "clear") {
      description.value = ""
    }

    this.$store.dispatch("livestream/saveDescription", description).finally(() => (this.showDropdown = false))
  }
}
