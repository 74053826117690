import { TestSessionsState } from "./test-sessions"
import { TestSession, PageInfo, User } from "@/generated/graphql"

export default {
  setLoading(state: TestSessionsState, loading: boolean) {
    state.loading = loading
  },
  setSessions(state: TestSessionsState, sessions: TestSession[]) {
    state.sessions = sessions
  },
  setReasons(state: TestSessionsState, reasons: string[]) {
    state.reasons = reasons
  },
  setPageInfo(state: TestSessionsState, pageInfo: PageInfo) {
    state.pageInfo = pageInfo
  },
  setTotalCount(state: TestSessionsState, counts: number) {
    state.totalCount = counts
  },
  setPage(state: TestSessionsState, page: number) {
    state.page = page
  },
  setItemsPerPage(state: TestSessionsState, items: number) {
    state.itemsPerPage = items
  },
  setUsers(state: TestSessionsState, users: User[]) {
    state.users = users
  },
  updateUser(state: TestSessionsState, user: string) {
    state.userId = parseInt(user)
  },
  updateFromDate(state: TestSessionsState, fromDate: string) {
    state.fromDate = fromDate
  },
  updateToDate(state: TestSessionsState, toDate: string) {
    state.toDate = toDate
  },
  updateSearchTerm(state: TestSessionsState, searchTerm: string) {
    state.searchTerm = searchTerm
  },
  clearFilter(state: TestSessionsState) {
    const fromDate = new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().substr(0, 10)
    const toDate = new Date().toISOString().substr(0, 10)
    state.searchTerm = ""
    state.userId = null
    state.fromDate = fromDate
    state.toDate = toDate
    state.page = 1
    state.pageInfo = {
      hasNextPage: true,
      hasPreviousPage: false,
      endCursor: "0",
      startCursor: "0",
    }
    state.itemsPerPage = 10
    state.totalCount = 0
  },
  resetPageInfo(state: TestSessionsState) {
    state.page = 1
    state.pageInfo = {
      hasNextPage: true,
      hasPreviousPage: false,
      endCursor: "0",
      startCursor: "0",
    }
    state.itemsPerPage = 10
    state.totalCount = 0
  },
  clearUser(state: TestSessionsState) {
    state.userId = null
  },
  clearSearch(state: TestSessionsState) {
    state.searchTerm = ""
  },
  updateFilterAndState(
    state: TestSessionsState,
    payload: { searchTerm: string; user: string; fromDate: string; toDate: string; page: any; rows: any }
  ) {
    const { searchTerm, user, fromDate, toDate, page, rows } = payload

    state.searchTerm = searchTerm ? searchTerm : ""
    state.userId = user ? parseInt(user) : null
    state.fromDate = fromDate ? fromDate : state.fromDate
    state.toDate = toDate ? toDate : state.toDate
    state.page = page ? parseInt(page) : state.page
    state.itemsPerPage = rows ? parseInt(rows) : state.itemsPerPage
    const endCursor = page && rows ? parseInt(page) * parseInt(rows) - parseInt(rows) : 0
    state.pageInfo.endCursor = endCursor.toString()
  },
}
