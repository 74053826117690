



















import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { Maybe, Scalars } from "@/generated/graphql"

@Component
export default class WarningLabel extends Vue {
  @Prop({ default: () => ({}) })
  testSession: Maybe<Scalars["Json"]>

  get show(): boolean {
    return this.testSession.warnings && this.testSession.warnings.country_code
  }

  get message(): string {
    if (this.testSession.warnings && this.testSession.warnings.country_code) {
      return this.testSession.warnings && this.testSession.warnings.country_code.join(" ")
    }
    return ""
  }
}
