import { CurrentUserState } from "./current-user"

export default {
  currentUser(state: CurrentUserState) {
    return state.currentUser
  },
  recentSessions(state: CurrentUserState) {
    return state.testSessions
  },
  itemsPerPage(state: CurrentUserState) {
    return state.itemsPerPage
  },
  page(state: CurrentUserState) {
    return state.page
  },
  pageInfo(state: CurrentUserState) {
    return state.pageInfo
  },
  totalCount(state: CurrentUserState) {
    return state.totalCount
  },
  isLoading(state: CurrentUserState) {
    return state.loading
  },
}
