













import Vue from "vue"
import { Component } from "vue-property-decorator"
import { LivestreamFilters } from "@/store/modules/livestream/livestream"

@Component
export default class DisplaySettings extends Vue {
  settings = [
    { key: "showWarningsAndErrorsOnly", label: "Only Errors and Warnings" },
    { key: "showUserInteractionsOnly", label: "Only User Interactions" },
    { key: "highlightUserInteractions", label: "Highlight User Interactions" },
    { key: "showTimestamps", label: "Show Timestamps" },
    { key: "condensed", label: "Condense Playheads" },
    { key: "showClock", label: "Show Clock" },
  ]

  get filters(): LivestreamFilters {
    return this.$store.getters["livestream/filters"]
  }
}
