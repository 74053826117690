















import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import catStreamStreamFactHeadline from "./fact_headline.vue"

@Component({
  components: {
    catStreamStreamFactHeadline,
  },
})
export default class FactAlerts extends Vue {
  @Prop({ default: () => ({}) })
  alerts!: { [key: string]: any[] }

  @Prop({ default: () => "warning" })
  type!: string

  get alertKeys(): string[] {
    return Object.keys(this.alerts)
  }
  get show(): boolean {
    return Object.keys(this.alerts).length > 0
  }
}
