







































import Vue from "vue"
import { Component } from "vue-property-decorator"
import { LivestreamFilters } from "@/store/modules/livestream/livestream"

@Component
export default class Filters extends Vue {
  get filters(): LivestreamFilters {
    return this.$store.getters["livestream/filters"]
  }

  get appIds(): string[] {
    return this.$store.getters["livestream/appIds"]
  }

  get filterTerm(): string {
    return this.$store.getters["livestream/filterTerm"]
  }

  get products(): string[] {
    return this.$store.getters["livestream/products"]
  }

  updateCustomFilter(value: string): void {
    this.$store.commit("livestream/updateCustomFilter", value)
  }
}
