






import Vue from "vue"
import { ROUTES } from "@/constants"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Maybe, Scalars } from "@/generated/graphql"

@Component
export default class ClaimButton extends Vue {
  @Prop({ default: () => undefined })
  catId!: string

  @Prop({ default: () => false })
  block!: boolean

  @Prop({ default: () => false })
  small!: boolean

  @Prop({ default: () => true })
  text!: boolean

  @Prop({ default: () => "" })
  color!: Maybe<Scalars["String"]>

  loading = false

  claim(): void {
    this.loading = true
    const catId = this.catId

    this.$store
      .dispatch("livestream/actions", {
        catId: this.catId,
        typeOfAction: "claim",
      })
      .then(response => {
        emitAlert(EVENTS.GLOBAL_SUCCESS, response.message)
        this.$router.push({ name: ROUTES.STREAM.name, params: { catId: catId } })
      })
      .catch(() => emitAlert(EVENTS.GLOBAL_ERROR, "There was an error claiming the session"))
      .finally(() => (this.loading = false))

    this.$gtag.event("claim", {
      event_category: "test_session",
      event_label: "Test Session Claimed",
      value: catId,
    })
  }
}
