




























import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import catStreamStreamFactHeadline from "./fact_headline.vue"

@Component({
  components: {
    catStreamStreamFactHeadline,
  },
})
export default class FactTable extends Vue {
  @Prop({ default: () => "" })
  headline!: string

  @Prop({ default: () => [] })
  details!: { [key: string]: string }[]

  @Prop({
    default: (): { [key: string]: string }[] => [
      { text: "Parameter", value: "key" },
      { text: "Value", value: "value" },
    ],
  })
  tableHeaders: any

  search = ""
}
