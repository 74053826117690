import { resetDefaults } from "@/store/modules/current-user/current-user"
import { CurrentUserState } from "./current-user"
import { PageInfo, User, TestSession } from "@/generated/graphql"

export default {
  setUser(state: CurrentUserState, user: User) {
    state.currentUser = user
  },
  setLogout(state: CurrentUserState) {
    const { currentUser, testSessions, loading, page, itemsPerPage, totalCount, pageInfo } = resetDefaults()
    state.currentUser = currentUser
    state.testSessions = testSessions
    state.loading = loading
    state.page = page
    state.itemsPerPage = itemsPerPage
    state.totalCount = totalCount
    state.pageInfo = pageInfo
    location.reload()
  },
  setSessions(state: CurrentUserState, sessions: TestSession[]) {
    state.testSessions = sessions
  },
  setTotalCount(state: CurrentUserState, counts: number) {
    state.totalCount = counts
  },
  setPageInfo(state: CurrentUserState, pageInfo: PageInfo) {
    state.pageInfo = pageInfo
  },
  setPage(state: CurrentUserState, page: number) {
    state.page = page
  },
  setItemsPerPage(state: CurrentUserState, items: number) {
    state.itemsPerPage = items
  },
  setLoading(state: CurrentUserState, loading: boolean) {
    state.loading = loading
  },
}
