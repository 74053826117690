import { client } from "@/services/apollo.service"
import TEST_SESSION_COMMENTS_QUERY from "@/graphql/ts_comments.gql"
import COMMENT_MUTATION from "@/graphql/comment_mutation.gql"
import DELETE_COMMENT_MUTATION from "@/graphql/delete_comment_mutation.gql"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { Commit } from "vuex"
import {
  DeleteCommentMutationMutationVariables,
  QueryTsCommentsArgs,
  CommentMutationMutationVariables,
} from "@/generated/graphql"

import store from "../../index"

export default {
  async loadComments({ commit }: { commit: Commit }, payload: QueryTsCommentsArgs) {
    commit("setLoading", true)

    try {
      const tsQuery = await client.query({
        query: TEST_SESSION_COMMENTS_QUERY,
        variables: {
          catId: payload.catId,
          first: payload.first,
        },
      })

      const testComments = tsQuery.data.tsComments.nodes
      const totalComments = tsQuery.data.tsComments.totalCount

      commit("setTsComments", testComments)
      if (payload.first && totalComments > payload.first) {
        commit("loadMoreComments", true)
      } else {
        commit("loadMoreComments", false)
      }
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Failed loading comments")
    }
    commit("setLoading", false)
  },

  async addComment({ commit }: { commit: Commit }, payload: CommentMutationMutationVariables) {
    if (!payload.message || !payload.message.trim()) {
      return emitAlert(EVENTS.GLOBAL_WARNING, "Comment cannot be blank")
    }
    commit("setLoading", true)
    try {
      const res = await client.mutate({
        mutation: COMMENT_MUTATION,
        variables: {
          catId: payload.catId,
          pingId: payload.pingId,
          type: payload.type,
          message: payload.message,
          userId: payload.userId,
        },
      })

      const { comment, message } = res.data.updateComment

      if (payload.type == "testSession") {
        commit("addComment", comment)
      } else if (payload.type == "ping") {
        store.commit("livestream/addComment", comment)
      }

      commit("updateMessage", "")
      emitAlert(EVENTS.GLOBAL_SUCCESS, message)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Failed adding comment")
    }

    commit("setLoading", false)
  },

  async updateComment({ commit }: { commit: Commit }, payload: CommentMutationMutationVariables) {
    commit("setLoading", true)

    try {
      const res = await client.mutate({
        mutation: COMMENT_MUTATION,
        variables: {
          id: payload.id,
          message: payload.message,
        },
      })

      const { comment, message } = res.data.updateComment
      const type = payload.type ? payload.type.toLowerCase() : payload.type

      if (type == "testsession") {
        commit("updateComment", comment)
      } else if (type == "ping") {
        store.commit("livestream/updateComment", comment)
      }
      emitAlert(EVENTS.GLOBAL_SUCCESS, message)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Failed editing comment")
    }

    commit("setLoading", false)
  },

  async deleteComment({ commit }: { commit: Commit }, payload: DeleteCommentMutationMutationVariables) {
    commit("setLoading", true)

    try {
      const res = await client.mutate({
        mutation: DELETE_COMMENT_MUTATION,
        variables: {
          id: payload.id,
          type: payload.type,
          pingId: payload.pingId,
        },
      })

      const { message } = res.data.deleteComment
      const type = payload.type.toLowerCase()

      if (type == "testsession") {
        commit("deleteComment", payload.id)
      } else if (type == "ping") {
        store.commit("livestream/deleteComment", payload.id)
      }
      emitAlert(EVENTS.GLOBAL_SUCCESS, message)
    } catch (_) {
      emitAlert(EVENTS.GLOBAL_ERROR, "Failed deleting comment")
    }
    commit("setLoading", false)
  },
}
