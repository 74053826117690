














































import Vue from "vue"
import { Component } from "vue-property-decorator"
import catStreamHeaderMenu from "@/components/stream/header_menu.vue"
import localTime from "@/components/common/local_time.vue"
import catStreamPresence from "@/components/common/user_presence.vue"
import NiPage from "@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue"
import { Maybe, PageInfo, AuditsQueryVariables, VersionConnection } from "@/generated/graphql"

@Component({
  components: {
    NiPage,
    localTime,
    catStreamPresence,
    catStreamHeaderMenu,
  },
})
export default class History extends Vue {
  created(): void {
    this.$store.dispatch("tsHistory/loadHistory", {
      catId: this.$route.params.catId,
      after: 0,
      first: 10,
    })
    //@ts-ignore
    this.$gtag.pageview("/history")
  }

  headers = [
    {
      text: "Timestamp",
      value: "created_at",
      align: "left",
      sortable: false,
      width: "200",
    },
    {
      text: "Event",
      value: "event",
      align: "left",
      sortable: false,
      width: "150",
    },
    {
      text: "User",
      value: "whodunnit",
      align: "left",
      sortable: false,
      width: "200",
    },
    {
      text: "Context",
      value: "item_type",
      align: "left",
      sortable: false,
      width: "150",
    },
    {
      text: "Changes",
      value: "object_changes",
      align: "left",
      sortable: false,
    },
  ]

  get loading(): boolean {
    return this.$store.getters["tsHistory/loading"]
  }
  get history(): Maybe<VersionConnection> {
    return this.$store.getters["tsHistory/history"]
  }
  get itemsPerPage(): number {
    return this.$store.getters["tsHistory/itemsPerPage"]
  }
  get page(): number {
    return this.$store.getters["tsHistory/page"]
  }
  get pageInfo(): PageInfo {
    return this.$store.getters["tsHistory/pageInfo"]
  }
  get totalCount(): number {
    const count = this.$store.getters["tsHistory/totalCount"]
    return count > 10 ? count : 10
  }

  paginate(isPrevious: boolean): void {
    const requestPayload: AuditsQueryVariables = { catId: this.$route.params.catId }

    if (isPrevious) {
      requestPayload.before = this.pageInfo?.startCursor ? parseInt(this.pageInfo.startCursor) : 0
      requestPayload.last = this.itemsPerPage
    } else {
      requestPayload.after = this.pageInfo?.endCursor ? parseInt(this.pageInfo.endCursor) : 0
      requestPayload.first = this.itemsPerPage
    }
    this.$store.dispatch(`tsHistory/loadHistory`, requestPayload)
  }

  stringHumanize(str: string): string | undefined {
    if (str != null) {
      const chars = str.split("_")
      for (let i = 0; i < chars.length; i++) {
        chars[i] = chars[i].charAt(0).toUpperCase() + chars[i].slice(1)
      }
      return chars.join(" ")
    }
  }

  updatePage(pageNumber: number): void {
    if (this.loading) return
    if (this.page === pageNumber) return
    let isPrevious = false
    if (pageNumber < this.page) {
      isPrevious = true
    }

    this.setPage(pageNumber)
    this.paginate(isPrevious)
  }

  updateItemsPerPage(val: number): void {
    if (this.itemsPerPage === val) return
    this.pageInfo.startCursor = "0"
    this.pageInfo.endCursor = "0"
    this.setItemsPerPage(val)
    this.paginate(false)
  }

  setPage(page: number): void {
    this.$store.commit(`tsHistory/setPage`, page)
  }

  setItemsPerPage(items: number): void {
    this.$store.commit(`tsHistory/setItemsPerPage`, items)
  }
}
