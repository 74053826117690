





































import Vue from "vue"
import { ROUTES } from "@/constants"
import { Component } from "vue-property-decorator"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"
import { TestSession, User } from "@/generated/graphql"

@Component
export default class EndSessionButton extends Vue {
  show = false
  loading = false

  get testSession(): TestSession {
    return this.$store.getters["livestream/testSession"]
  }

  get currentUser(): User {
    return this.$store.getters["currentUser/currentUser"]
  }

  get streamOwner(): User {
    const a = this.$store.getters["livestream/streamOwner"]
    return a
  }

  get disableBtn(): string {
    return this.$store.getters["livestream/disableBtnDotUi"]
  }

  get displayButton(): boolean | undefined | null {
    const ownerId = this.streamOwner ? this.streamOwner.id : null
    return this.testSession && this.testSession.active && ownerId == this.currentUser.id
  }

  showDialog(show: boolean) {
    this.show = show
  }

  showErrorFlash(message: string): void {
    emitAlert(EVENTS.GLOBAL_ERROR, message)
    this.showDialog(false)
    this.$router.push({ name: ROUTES.DASHBOARD.name })
  }

  endSession(): void {
    if (!this.streamOwner) {
      this.showErrorFlash("You cannot end a session that's not claimed")
      return
    }
    if (this.streamOwner.id != this.currentUser.id) {
      this.showErrorFlash("You cannot end a session that's not yours")
      return
    }
    if (this.testSession.initiator == "dot_ui") {
      this.showErrorFlash("You must end via the dot ui")
      return
    }
    this.loading = true
    this.$store
      .dispatch("livestream/actions", {
        catId: this.testSession.catId,
        typeOfAction: "end_session",
      })
      .then(res => {
        if (res.status !== "error") {
          emitAlert(EVENTS.GLOBAL_SUCCESS, "Sucessfully ended test session")
          this.$router.push({ name: ROUTES.DASHBOARD.name })
        }
      })
      .catch(() => emitAlert(EVENTS.GLOBAL_ERROR, "Error ending test session"))
      .finally(() => (this.loading = false))
    this.showDialog(false)

    this.$gtag.event("end", {
      event_category: "test_session",
      event_label: "End Test Session Click",
      value: this.testSession.catId,
    })
  }
}
