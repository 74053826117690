




import Vue from "vue"
import { Component } from "vue-property-decorator"
import { Prop } from "vue-property-decorator"
import { format } from "date-fns"

@Component
export default class CatTimestamp extends Vue {
  @Prop({ default: () => "" })
  time!: string

  @Prop({ default: () => true })
  small!: boolean

  @Prop({ default: () => "" })
  prefix!: string

  formattedDate(): string {
    return format(new Date(parseInt(this.time)), "HH:mm:ss")
  }
}
