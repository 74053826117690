import actions from "./actions.livestream"
import getters from "./getters.livestream"
import mutations from "./mutations.livestream"
import { Comment, User, TestSession } from "@/generated/graphql"

export interface LivestreamFilters {
  condensed: boolean
  custom: string
  highlightUserInteractions: boolean
  showClock: boolean
  showOnlyAppIds: { [key: string]: boolean }
  showOnlyProducts: { [key: string]: boolean }
  showTimestamps: boolean
  showUserInteractionsOnly: boolean
  showWarningsAndErrorsOnly: boolean
}

export interface LivestreamPing {
  app_id: string
  cat_time: string
  commented_on: boolean | null
  description: string | null
  error_messages: { [key: string]: string[] }
  filter_type: string
  id: number
  labels: string[]
  warning_messages: { [key: string]: string[] }
  condensedInfo?: any
}

export interface LivestreamState {
  appIds: string[]
  catId: string | null
  connection: any
  descriptions: string[]
  focused: number
  loading: boolean
  pingLoading: boolean
  pings: LivestreamPing[]
  presence: any
  testSession: TestSession | undefined | any
  selectedPing: { details: { [key: string]: string | number }; filterType: string; comments: Comment[] }
  filters: LivestreamFilters | any
  user: User | undefined
}

export const state: LivestreamState = {
  appIds: [],
  catId: null,
  connection: null,
  descriptions: [],
  focused: 0,
  loading: false,
  pingLoading: false,
  pings: [],
  presence: [],
  testSession: undefined,
  selectedPing: {
    details: {},
    filterType: "",
    comments: [],
  },
  filters: {
    showWarningsAndErrorsOnly: false,
    showUserInteractionsOnly: false,
    highlightUserInteractions: true,
    showOnlyProducts: {},
    showOnlyAppIds: {},
    showTimestamps: true,
    showClock: false,
    custom: "",
    condensed: false,
  },
  user: undefined,
}

const Livestream = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
}

export default Livestream
