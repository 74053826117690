import { getPreflightIndexById } from "./preflight"
import { PreflightState } from "./preflight"
import { Maybe, PageInfo, Preflight, Scalars } from "@/generated/graphql"

export default {
  setLoading(state: PreflightState, loading: boolean) {
    state.loading = loading
  },
  setPageInfo(state: PreflightState, pageInfo: PageInfo) {
    state.pageInfo = pageInfo
  },
  setTotalCount(state: PreflightState, counts: number) {
    state.totalCount = counts
  },
  setPage(state: PreflightState, page: number) {
    state.page = page
  },
  setItemsPerPage(state: PreflightState, items: number) {
    state.itemsPerPage = items
  },
  setIdentifier(state: PreflightState, { dev_type_ids, dev_ids }: { dev_type_ids: string[]; dev_ids: string[] }) {
    state.identifier.devTypeIds = dev_type_ids
    state.identifier.devIds = dev_ids
  },
  // broken
  addPreflight(state: PreflightState, pf: any) {
    if (pf[1] == null && Object.keys(pf[0]).length != 0) return (state.preflights = [pf[0], ...state.preflights])
    state.preflight = pf[0]
  },
  removePreflight(state: PreflightState, id: number | string) {
    state.preflights = state.preflights.filter(p => p.id !== id)
  },
  setPreflights(state: PreflightState, preflights: Preflight[]) {
    state.preflights = preflights
  },
  setPfTypes(state: PreflightState, pfTypes: Maybe<Scalars["Json"]>) {
    state.pfTypes = pfTypes
  },
  update({ preflights }: { preflights: Preflight[] }, { id, ...updates }: any) {
    const i = getPreflightIndexById(preflights, id)
    Object.assign(preflights[i], updates)
  },
  setConnection(state: PreflightState, conn: any) {
    state.connection = conn
  },
}
