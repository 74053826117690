// eslint-disable-next-line
// @ts-ignore
import Vue from "vue"
import App from "@/App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import i18n from "./plugins/i18n"
import "./plugins/components"
// import "./plugins/moment"
// import "./plugins/sentry"

// eslint-disable-next-line
// @ts-ignore
import { AuthPlugin } from "@nielsen-media/auth-api/src/auth"

import { EventBus } from "@/mixins/eventBus"

Vue.config.productionTip = false
Vue.use(AuthPlugin, { router, EventBus })

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount("#app")
