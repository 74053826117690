








import Vue from "vue"
import { Component } from "vue-property-decorator"
import { User } from "@/generated/graphql"

@Component
export default class UserPresence extends Vue {
  get presence(): User[] {
    return this.$store.getters["livestream/presence"]
  }
}
