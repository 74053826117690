



















































































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import claimButton from "@/components/common/buttons/claim_button.vue"
import catTestSessionWarningLabel from "./test_session_warning_label.vue"
import { ROUTES } from "@/constants"
import { Maybe, Scalars } from "@/generated/graphql"

@Component({
  components: {
    claimButton,
    catTestSessionWarningLabel,
  },
})
export default class ActiveSessionsTable extends Vue {
  @Prop({
    default: () => ({
      appId: "",
      myLocation: false,
      product: [],
    }),
  })
  filter: any

  headers = [
    { text: "Client", value: "app_id", align: "left", sortable: true },
    { text: "App Ids", value: "app_ids", align: "left", sortable: false },
    { text: "Updated", value: "updated_at", align: "left", sortable: true },
    { text: "Action", value: "", align: "center", sortable: false },
  ]
  livestreamRouteName = ROUTES.STREAM.name

  created() {
    this.$store.dispatch("activeSessions/connect")
    this.$store.dispatch("activeSessions/loadActiveSessions")
  }

  destroyed() {
    this.$store.dispatch("activeSessions/disconnect")
  }

  get currentUser() {
    return this.$store.getters["currentUser/currentUser"]
  }

  get loading() {
    return this.$store.getters["activeSessions/loading"]
  }

  get activeSessions() {
    let sessions = this.$store.getters["activeSessions/all"]
    if (this.currentUser.allowedCountries && this.currentUser.allowedCountries.length != 0) {
      sessions = sessions.filter((s: Maybe<Scalars["Json"]>) => this.currentUser.allowedCountries.includes(s.country_code))
    }

    if (this.filter.myLocation) {
      sessions = sessions.filter((s: Maybe<Scalars["Json"]>) => s.client_ip === this.currentUser.currentLoginIp)
    }

    if (this.filter.appId && this.filter.appId !== "") {
      sessions = sessions.filter((s: Maybe<Scalars["Json"]>) =>
        s.apps.some((item: Maybe<Scalars["Json"]>) => item.app_id.startsWith(this.filter.appId.toUpperCase()))
      )
    }

    if (this.filter.product && this.filter.product.length > 0) {
      const product = this.filter.product
        .toLocaleString()
        .toLowerCase()
        .split(",")
      sessions = sessions.filter((s: Maybe<Scalars["Json"]>) =>
        product.some((substring: string) => s.session_type.includes(substring))
      )
    }
    return sessions
  }
}
