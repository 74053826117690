import actions from "./actions.test-sessions"
import getters from "./getters.test-sessions"
import mutations from "./mutations.test-sessions"
import { PageInfo, TestSession, User } from "@/generated/graphql"

export interface TestSessionsState {
  currentDate: string
  fromDate: string
  loading: boolean
  page: number
  pageInfo: PageInfo
  itemsPerPage: number
  totalCount: number
  reasons: string[]
  searchTerm: string
  searchUser: User | null
  sessions: TestSession[]
  toDate: string
  userId: number | string | null
  users: User[]
}

export const state: TestSessionsState = {
  currentDate: new Date().toISOString().substr(0, 10),
  fromDate: new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().substr(0, 10),
  loading: false,
  page: 1,
  pageInfo: {
    hasNextPage: true,
    hasPreviousPage: false,
    endCursor: "0",
    startCursor: "0",
  },
  itemsPerPage: 10,
  totalCount: 0,
  reasons: [],
  searchTerm: "",
  searchUser: null,
  sessions: [],
  toDate: new Date().toISOString().substr(0, 10),
  userId: null,
  users: [],
}

const TestSessions = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
}

export default TestSessions
