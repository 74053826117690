





















import { Component, Watch } from "vue-property-decorator"
import { navigation } from "@/router/navigation"
import NiApp from "@/components/external/ni-app/NiApp.vue"
import { Action, Getter } from "vuex-class"
import axios from "axios"
// //@ts-ignore
import { CurrentUser } from "@nielsen-media/nlsn-vue-ui/lib/current-user"
import { mixins } from "vue-class-component"
import { ActionMethod } from "vuex"
import { WithEventBus } from "@/mixins/eventBus"
import { EVENTS } from "./constants"
import { ROUTES } from "@/constants"
import { emitAlert } from "@/helpers/alert.helper"

@Component({
  components: {
    NiApp,
  },
})
export default class App extends mixins(WithEventBus) {
  title = "Certification Assistance Tool (CAT)"
  showSnackbar = false
  snackbarText = ""
  snackStatus = "warning"
  showNavBar = true
  navList = navigation
  knownApps = []
  icon = "/images/cat.svg"

  @Getter(`currentUser/currentUser`)
  currentUser!: { name: ""; email: ""; avatarUrl: "" }

  @Action(`currentUser/load`)
  performFetchUser!: ActionMethod

  created(): void {
    this.eventBus.$on(EVENTS.AUTHENTICATED, async () => {
      await this.performFetchUser()
    })
    this.eventBus.$on(EVENTS.GLOBAL_ERROR, (alertData: Error) => this.showAlert(alertData, "error"))
    this.eventBus.$on(EVENTS.GLOBAL_WARNING, (alertData: Error) => this.showAlert(alertData, "warning"))
    this.eventBus.$on(EVENTS.GLOBAL_SUCCESS, (alertData: any) => this.showAlert(alertData, "success"))
    this.loadApplicationList()
  }

  showAlert(data: any, status: string): void {
    this.snackStatus = status
    this.showSnackbar = true
    this.snackbarText = data.message || data
  }

  loadApplicationList() {
    axios
      .get(`https://s3.amazonaws.com/nlsn-eng-assets/ui/v1/applications.json`)
      .then(res => {
        this.knownApps = res.data.applications.filter((e: { [key: string]: string }) => e.code !== "CAT")
      })
      .catch(() => {
        emitAlert(EVENTS.GLOBAL_ERROR, "Failed loading app list")
      })
  }

  @Watch("currentUser")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPropertyChanged(value: string, oldValue: string): void {
    CurrentUser.$emit("set", {
      name: this.currentUser.name,
      email: this.currentUser.email,
      picture: this.currentUser.avatarUrl,
    })

    //@ts-ignore
    if (this.$auth.hasPermissions("cat.preflight")) {
      this.navList.top.push({
        icon: "flight",
        text: ROUTES.PREFLIGHT.name,
        route: ROUTES.PREFLIGHT.path,
      })
    }
  }
}
