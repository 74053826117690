











import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { emitAlert } from "@/helpers/alert.helper"
import { EVENTS } from "@/constants"

@Component
export default class FactCopy extends Vue {
  @Prop({ default: () => "" })
  details!: string

  @Prop({ default: () => "" })
  pingType!: string

  get copyText(): string {
    return this.pingType.includes("audit") ? "COPY FILE NAME" : "COPY METADATA"
  }

  onCopy(): void {
    emitAlert(EVENTS.GLOBAL_SUCCESS, "Copied to clipboard")
  }
  onError(): void {
    emitAlert(EVENTS.GLOBAL_ERROR, "Failed copying to clipboard")
  }
}
