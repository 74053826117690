import { ApolloLink, ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core"
import { setContext } from "@apollo/client/link/context"
import { getToken } from "@nielsen-media/auth-api/src/auth"
import { EventBus } from "@/mixins/eventBus"
import { EVENTS } from "@/constants"

// let authService: any | null = null;
let authFired = false
let token: any
const cache = new InMemoryCache({})

function retrieveToken() {
  return getToken()
}

const authLink = setContext((_, { headers }) => {
  if (!process.env.VUE_APP_AUTH_BYPASS) {
    const setHeaders = async () => {
      token = await retrieveToken()
      if (token != undefined) {
        if (!authFired) {
          authFired = true
          setTimeout(() => {
            EventBus.$emit(EVENTS.AUTHENTICATED)
          }, 10)
        }
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
            identification: process.env.VUE_APP_CAT_UI_CLIENT_ID,
          },
        }
      }
    }
    return setHeaders()
  } else {
    setTimeout(() => {
      EventBus.$emit(EVENTS.AUTHENTICATED)
    }, 10)
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem("cypress_mock_auth")}`,
        identification: process.env.VUE_APP_CAT_UI_CLIENT_ID,
      },
    }
  }
})

function omitTypename(key: string, value: unknown) {
  return key === "__typename" ? undefined : value
}

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }

    return forward(operation)
  })
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    createOmitTypenameLink(),
    authLink.concat(new HttpLink({ uri: process.env.VUE_APP_API_URL })),
  ]),
  cache: cache,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
})
