










import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import catStreamStreamFactHeadline from "./fact_headline.vue"

@Component({
  components: {
    catStreamStreamFactHeadline,
  },
})
export default class FactRaw extends Vue {
  @Prop({ default: () => "" })
  headline!: string

  @Prop({ default: () => "" })
  details!: string
}
