













































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component({ components: {}, props: {} })
export default class NavBar extends Vue {
  @Prop() items!: {}[]
}
