


















































import Vue from "vue"
import { Component } from "vue-property-decorator"
import pingDetail from "@/components/stream/ping_detail.vue"
import panels from "@/components/stream/panels.vue"
import streamPing from "@/components/stream/stream_ping.vue"
import catTimestamp from "@/components/common/cat_timestamp.vue"
import catStreamPresence from "@/components/common/user_presence.vue"
import NiPage from "@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue"
import { EventBus } from "@/mixins/eventBus"
import { Ping, TestSession } from "@/generated/graphql"
import { LivestreamFilters } from "@/store/modules/livestream/livestream"

@Component({
  components: {
    NiPage,
    pingDetail,
    panels,
    streamPing,
    catTimestamp,
    catStreamPresence,
  },
})
export default class Stream extends Vue {
  time = Date.now().toString()
  interval: null | ReturnType<typeof setTimeout> = null
  panels = [{ header: "Filter" }]

  mounted(): void {
    const catId = this.$route?.params?.catId
    const pingId = this.$route?.query?.pingId

    if (catId) this.$store.dispatch("livestream/connect", this.$route.params.catId)

    if (pingId) {
      this.$store
        .dispatch("livestream/loadPingDetail", {
          id: pingId,
        })
        .then(() => this.scollToPing())
        .catch(() => {
          EventBus.$emit("GLOBAL_ERROR", new Error("Failed loading ping info"))
        })
    }

    this.interval = setInterval(() => (this.time = Date.now().toString()), 1000)
    //@ts-ignore
    this.$gtag.pageview("/stream")
  }

  beforeDestroy(): void {
    this.$store.dispatch("livestream/disconnect")
    this.resetFilters()
    this.clearTestSession()
    clearInterval(this.interval as NodeJS.Timeout)
  }

  updated(): void {
    this.scrollDown()
  }

  get pings(): Ping[] {
    return this.$store.getters["livestream/pings"]
  }

  get focused(): number {
    return this.$store.getters["livestream/focused"]
  }

  set focused(id: number) {
    this.$store.commit("livestream/setFocused", id)
  }

  get title(): string {
    return this.$store.getters["livestream/title"]
  }

  get loading(): boolean {
    return this.$store.getters["livestream/loading"]
  }

  get pingLoading(): boolean {
    return this.$store.getters["livestream/pingLoading"]
  }

  get selectedPing(): Ping {
    return this.$store.getters["livestream/selectedPing"]
  }

  get filters(): LivestreamFilters {
    return this.$store.getters["livestream/filters"]
  }

  get integrationName(): string {
    return this.$store.getters["livestream/integrationName"]
  }

  get testSession(): TestSession {
    return this.$store.getters["livestream/testSession"]
  }

  get scrollerHeight(): number {
    const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    return height - 170
  }

  get truncatedData(): string {
    return this.$store.getters["livestream/truncatedData"]
  }

  resetFilters(): void {
    this.$store.commit("livestream/resetFilters")
  }

  clearTestSession(): void {
    this.$store.commit("livestream/clearTestSession")
  }

  scrollDown(): void {
    //@ts-ignore
    const el = this.$refs.scroller.$el
    if (el.scrollTop > el.scrollHeight - el.offsetHeight - 40) {
      el.scrollTop = el.scrollHeight
      if (this.focused == this.pings.length - 2) {
        this.focused = this.pings.length - 1
      }
    }
  }

  scollToPing(): void {
    if (this.focused > -1) {
      //@ts-ignore
      const el = this.$refs.scroller.$el
      const multiplier = this.pings.length < 2000 ? 28 : 29
      el.scrollTop = this.focused * multiplier
    }
  }
}
