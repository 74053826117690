import { TsHistoryState } from "./ts-history"
import { Version, PageInfo } from "@/generated/graphql"

export default {
  setHistory(state: TsHistoryState, versions: Version[]) {
    state.history = versions
  },
  setPageInfo(state: TsHistoryState, pageInfo: PageInfo) {
    state.pageInfo = pageInfo
  },
  setTotalCount(state: TsHistoryState, counts: number) {
    state.totalCount = counts
  },
  setPage(state: TsHistoryState, page: number) {
    state.page = page
  },
  setItemsPerPage(state: TsHistoryState, items: number) {
    state.itemsPerPage = items
  },
  setLoading(state: TsHistoryState, loading: boolean) {
    state.loading = loading
  },
}
