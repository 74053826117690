



























































































import Vue from "vue"
import { Component } from "vue-property-decorator"
import catMenu from "@/components/common/menu.vue"
import catSearchFormComponent from "@/components/tests/search_form_component.vue"
import catSearchActiveFilters from "@/components/tests/index_active_filters.vue"
import localTime from "@/components/common/local_time.vue"
import NiPage from "@nielsen-media/nlsn-vue-ui/lib/components/NiPage.vue"
import { ROUTES } from "@/constants"
import { PageInfo, TestSession } from "@/generated/graphql"

@Component({
  components: {
    NiPage,
    catMenu,
    localTime,
    catSearchFormComponent,
    catSearchActiveFilters,
  },
})
export default class TestSessions extends Vue {
  title = "Test Sessions"
  headers = [
    { text: "#", value: "id", align: "left", sortable: true, width: "70" },
    { text: "Title", value: "title", align: "left", sortable: false },
    { text: "Active", value: "active", align: "left", sortable: false },
    { text: "User", value: "user.name", align: "left", sortable: false },
    { text: "Created", value: "created_at", align: "left", sortable: true },
    { text: "Actions", value: "actions", align: "left", sortable: false },
  ]
  livestreamRouteName = ROUTES.STREAM.name

  created(): void {
    const { from, to, search, user, page, rows, before, last, after, first } = this.$route.query

    this.$store.commit(`testSessions/updateFilterAndState`, {
      fromDate: from,
      toDate: to,
      searchTerm: search,
      user,
      page,
      rows,
      before,
      last,
      after,
      first,
    })
    this.paginate(false)
    //@ts-ignore
    this.$gtag.pageview("/tests")
  }

  get sessions(): TestSession[] {
    return this.$store.getters["testSessions/sessions"]
  }
  get loading(): boolean {
    return this.$store.getters["testSessions/loading"]
  }
  get itemsPerPage(): number {
    return this.$store.getters["testSessions/itemsPerPage"]
  }
  get page(): number {
    return this.$store.getters["testSessions/page"]
  }
  get pageInfo(): PageInfo {
    return this.$store.getters["testSessions/pageInfo"]
  }
  get totalCount(): number {
    const count = this.$store.getters["testSessions/totalCount"]
    return count > 10 ? count : 10
  }

  paginate(isPrevious: boolean): void {
    this.$store.dispatch(`testSessions/paginate`, isPrevious)
  }

  updatePage(pageNumber: number): void {
    if (this.loading) return
    if (this.page === pageNumber) return
    let isPrevious = false
    if (pageNumber < this.page) {
      isPrevious = true
    }

    this.setPage(pageNumber)
    this.paginate(isPrevious)
  }

  updateItemsPerPage(val: number): void {
    if (this.itemsPerPage === val) return
    this.pageInfo.startCursor = "0"
    this.pageInfo.endCursor = "0"
    this.setItemsPerPage(val)
    this.paginate(false)
  }

  setPage(page: number): void {
    this.$store.commit(`testSessions/setPage`, page)
  }

  setItemsPerPage(items: number): void {
    this.$store.commit(`testSessions/setItemsPerPage`, items)
  }

  stringHumanize(str: string): string | undefined {
    if (str != null) {
      const chars = str.split("_")
      for (let i = 0; i < chars.length; i++) {
        chars[i] = chars[i].charAt(0).toUpperCase() + chars[i].slice(1)
      }
      return chars.join(" ")
    }
  }
}
