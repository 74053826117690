






import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class PreflightStatusChip extends Vue {
  @Prop({ default: () => false })
  active!: boolean

  @Prop({ default: () => false })
  locked!: boolean

  @Prop({ default: () => undefined })
  id: number | undefined

  toggle(): void {
    if (!this.id || this.locked) return
    this.$store.dispatch("preflights/toggleStatus", this.id)
  }
}
