




































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { TestSession } from "@/generated/graphql"
import { LivestreamPing } from "@/store/modules/livestream/livestream"

@Component
export default class CondensedPromptModal extends Vue {
  @Prop({ default: () => false })
  clearDescription!: boolean

  @Prop({ default: () => false })
  showModal!: boolean

  @Prop({ default: () => false })
  isDescription!: boolean

  @Prop({ default: () => "" })
  selectedDescription!: string

  @Prop({ default: () => ({}) })
  ping!: LivestreamPing

  condensedId = ""

  get testSession(): TestSession {
    return this.$store.getters["livestream/testSession"]
  }

  get modal(): boolean {
    return this.showModal
  }

  set modal(value: boolean) {
    this.$emit("set-modal", value)
  }

  get question(): string {
    if (this.isDescription) return "update?"
    return "load?"
  }

  setModal(bool: boolean): void {
    this.$emit("set-modal", bool)
  }

  loadPingDetail(id: number): void {
    const focusedPing = this.startingPlayheadId() // to handle end ping loaded but condensed row focused class
    this.$emit("load-ping-detail", false, id, focusedPing)
    this.setModal(false)
  }

  saveDescription(condensedId: number): void {
    const action = this.clearDescription ? "clear" : "update"
    this.$emit("save-description", condensedId, action)
    this.setModal(false)
  }

  startingPlayheadId(): number | null {
    return this.ping.condensedInfo && this.ping.condensedInfo.start ? this.ping.condensedInfo.start.id : null
  }

  endPlayheadId(): number | null {
    return this.ping.condensedInfo && this.ping.condensedInfo.end ? this.ping.condensedInfo.end.id : null
  }
}
